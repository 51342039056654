import React from 'react'
import { useEffect, useState } from 'react';
import call from "../../../../assets/images/call.svg";
import { Pagination } from '../../../common/pagination/pagination';

import map from "../../../../assets/images/map.svg";
import envelop from "../../../../assets/images/envelop.svg";
import InputMask from 'react-input-mask';
import { Footer } from '../../../common/footer/footer';
import { uploadURL, NEW_URL } from '../../../../constants/applicationConstants.js';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { formatCanadianPhoneNumber } from '../../../../shared/helper'
import { baseURL } from '../../../../constants/applicationConstants.js';
import axios from 'axios';
import Sidebar from "../sidebar/sidebar.js";

export const CustomerAskTheExpertJsx = (props) => {
  const { t } = useTranslation();
  const {
    categoryData,
    subCategoryData,
    formData,
    onInputChange,
    formError,
    onTouchHandler,
    formSubmitButtonDisable,
    onSubmitButtonClick,
    onPaginationButtonClick,
    expertData,
    onViewProfieClick,
    // meta
  } = props;

  const typeData = [
    {
      "label": "Expert",
      "value": "Expert"
    },
    {
      "label": "Institute",
      "value": "Institute"
    }
  ]

  const planDataSet = [
    {
      "label": "Monthly",
      "value": "monthly"
    },
    {
      "label": "Annually",
      "value": "annually"
    }
  ]
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])

  const [posts, setPosts] = useState([]);
  const [meta, setMeta] = useState({});
  const [totalPosts, setTotalPosts] = useState(0);
  const [fetch, setFetch] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(0);
  const [page, setPage] = useState(1);




  const handleOnLoadMore = () => {
    setPage(page + 1);

    // fetchPosts();

  };



  // {t("PRINT.TITLE")}
  return (
    <>
      <section className="entiredashboard admindashboard">
        <div className="container">

          <div className="row">
            <div className="col-lg-3 col-sm-3">


              <div className="dashboardleftpanel martop40">

                <div className="dashboardlink martop40">
                  <Sidebar />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-9">
              <div className="dashboardrightpanel martop40">
                <h2 className="h2_style">Submit Plans</h2>

              </div>
              <div className="dashboardrightpanel martop40">
              <div className="row">
              <div className="col-lg-3 col-sm-3">
                <div className="input-container form-group">
                  <input
                    type="text"
                    className="form-control input"
                    placeholder=" "
                    name="name"
                    onChange={onInputChange}
                    onBlur={onTouchHandler}
                    value={formData.name}
                  />
                  <div className="placeholder">Plan Name</div>
                </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                <div className="input-container form-group">
                  <select
                    className="input form-control form-select"
                    placeholder=" "
                    name="planFor"
                    value={formData.planFor}
                    onChange={onInputChange}
                    onBlur={onTouchHandler}
                  >
                    <option value="">All</option>
                    {typeData?.map((item, i) => {
                      return (
                        <>
                          <option value={item.value} selected={item.value === formData.subCategory}>{item.label}</option>
                        </>
                      )
                    })}
                  </select>
                  <div className="placeholder">Plan For</div>
                </div>
</div>
<div className="col-lg-3 col-sm-3">
                <div className="input-container form-group">
                  <select
                    className="input form-control form-select"
                    placeholder=" "
                    name="category"
                    onChange={onInputChange}
                    onBlur={onTouchHandler}
                  >
                    <option value="">All</option>
                    {categoryData?.map((item) => {
                      return (
                        <>
                          <option value={item.institutionCategoryId} selected={item.institutionCategoryId === formData.category}>{item.name}</option>
                        </>
                      )
                    })}
                  </select>
                  <div className="placeholder">Category</div>
                </div>
</div>
<div className="col-lg-3 col-sm-3">
                <div className="input-container form-group">
                  <input
                    type="text"
                    className="form-control input"
                    placeholder=" "
                    name="stripeCode"
                    onChange={onInputChange}
                    onBlur={onTouchHandler}
                    value={formData.stripeCode}
                  />
                  <div className="placeholder">Stripe Code</div>
                </div>
</div>
<div className="col-lg-3 col-sm-3">
                <div className="input-container form-group">
                  <select
                    className="input form-control form-select"
                    placeholder=" "
                    name="planType"
                    onChange={onInputChange}
                    onBlur={onTouchHandler}
                  >
                    <option value="">All</option>
                    {planDataSet?.map((item) => {
                      return (
                        <>
                          <option value={item.value} selected={item.value === formData.subCategory}>{item.label}</option>
                        </>
                      )
                    })}
                  </select>
                  <div className="placeholder">Plan Type</div>
                </div>
</div>
<div className="col-lg-3 col-sm-3">
                <div className="input-container form-group">
                  <input
                    type="text"
                    className="form-control input"
                    placeholder=" "
                    name="price"
                    onChange={onInputChange}
                    onBlur={onTouchHandler}
                    value={formData.price}
                  />
                  <div className="placeholder">Price</div>
                </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                <div className="input-container form-group">
                  <input
                    type="text"
                    className="form-control input"
                    placeholder=" "
                    name="adsLimit"
                    onChange={onInputChange}
                    onBlur={onTouchHandler}
                    value={formData.adsLimit}
                  />
                  <div className="placeholder">Ads Limit</div>
                </div>
               </div>
               <div className="col-lg-3 col-sm-3">
                <div className="input-container form-group">
                  <input
                    type="text"
                    className="form-control input"
                    placeholder=" "
                    name="offerLimit"
                    onChange={onInputChange}
                    onBlur={onTouchHandler}
                    value={formData.offerLimit}
                  />
                  <div className="placeholder">Offer Limit</div>
                </div>
               </div>
               <div className="col-lg-3 col-sm-3">
                <div className="input-container form-group">
                  <input
                    type="text"
                    className="form-control input"
                    placeholder=" "
                    name="productLimit"
                    onChange={onInputChange}
                    onBlur={onTouchHandler}
                    value={formData.productLimit}
                  />
                  <div className="placeholder">Product Limit</div>
                </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                <div className="input-container form-group">
                  <input
                    type="text"
                    className="form-control input"
                    placeholder=" "
                    name="expertLimit"
                    onChange={onInputChange}
                    onBlur={onTouchHandler}
                    value={formData.expertLimit}
                  />
                  <div className="placeholder">Expert Limit</div>
                </div>
</div>
<div className="col-lg-3 col-sm-3">
                <div className="input-container form-group">
                  <input
                    type="text"
                    className="form-control input"
                    placeholder=" "
                    name="secondaryStripeCode"
                    onChange={onInputChange}
                    onBlur={onTouchHandler}
                    value={formData.secondaryStripeCode}
                  />
                  <div className="placeholder">Secondary Stripe Code</div>
                </div>
</div>
<div className="col-lg-3 col-sm-3">
                <div className="input-container form-group">
                  <input
                    type="text"
                    className="form-control input"
                    placeholder=" "
                    name="secondaryPrice"
                    onChange={onInputChange}
                    onBlur={onTouchHandler}
                    value={formData.secondaryPrice}
                  />
                  <div className="placeholder">Secondary Price</div>
                </div>
</div>


                <div className="input-container form-group"><button type="submit" className="ctaonebutton" disabled={formSubmitButtonDisable} onClick={() => onSubmitButtonClick()}>Submit</button></div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </section>

      <Footer />
    </>

  );
};

export default CustomerAskTheExpertJsx;