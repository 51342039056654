import React from 'react'
import { useEffect, useState } from 'react';
import call from "../../../../assets/images/call.svg";
import { Pagination } from '../../../common/pagination/pagination';

import map from "../../../../assets/images/map.svg";
import envelop from "../../../../assets/images/envelop.svg";
import InputMask from 'react-input-mask';
import { Footer } from '../../../common/footer/footer';
import { uploadURL, NEW_URL } from '../../../../constants/applicationConstants.js';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { formatCanadianPhoneNumber } from '../../../../shared/helper'
import { baseURL } from '../../../../constants/applicationConstants.js';
import moment from 'moment';
import Sidebar from "../sidebar/sidebar.js";

import axios from 'axios';

export const CustomerAskTheExpertJsx = (props) => {
  const { t } = useTranslation();
  const {
    categoryData,
    subCategoryData,
    formData,
    onInputChange,
    formError,
    onTouchHandler,
    onDeleteButtonClick,
    onSubmitButtonClick,
    onEditButtonClick,
    formSubmitButtonDisable,
    expertData,
    onViewProfieClick,
    // meta
  } = props;
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])

  const [posts, setPosts] = useState([]);
  const [meta, setMeta] = useState({});
  const [totalPosts, setTotalPosts] = useState(0);
  const [fetch, setFetch] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(0);
  const [page, setPage] = useState(1);




  const handleOnLoadMore = () => {
    setPage(page + 1);

    // fetchPosts();

  };



  // {t("PRINT.TITLE")}
  return (
    <>
      <section class="entiredashboard admindashboard">

        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-3">


              <div className="dashboardleftpanel martop40">

                <div className="dashboardlink martop40">
                  <Sidebar />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-9">

              <div className="dashboardrightpanel martop40">
                <h2>All plans</h2>



                <div className="expertsection martop30 mb-5">
                  {
                    expertData.length > 0 ?

                      <div className="searchdetails">
                        <table className="searchdetailsdb">
                          <tr>
                            <th>Sl No</th>
                            <th>Plan name</th>
                            <th>Plan type</th>
                            <th>Plan duration</th>
                            <th>Plan for</th>
                            <th>Category</th>
                            <th>Ads Limit</th>
                            <th>Offer Limit</th>
                            <th>Product Limit</th>
                            <th>Experts Limit</th>
                            <th>Price</th>
                            <th>Action</th>
                          </tr>

                          {expertData?.map((data, index) => (
                            <>
                              <tr>
                                <td>{index + 1}</td>
                                <td>{data?.subscriptionPlan?.planName}</td>
                                <td>{data?.planType}</td>
                                <td>{data?.duration} days</td>
                                <td>{data?.subscriptionPlan?.planFor}</td>
                                <td>{data?.subscriptionPlan?.institutionCategory?.name}</td>
                                <td>{data?.adsLimit}</td>
                                <td>{data?.offersLimit}</td>
                                <td>{data?.productsLimit}</td>
                                <td>{data?.expertsLimit}</td>
                                <td>{data?.price} $</td>
                                <td>
                                  <button className="btn btn-success" onClick={() => onEditButtonClick(data?.subscriptionPlanTypeId)}>Edit</button>
                                  <button className="btn btn-danger" onClick={() => onDeleteButtonClick(data?.subscriptionPlanTypeId)} >delete</button></td>
                              </tr>
                            </>
                          ))}

                        </table>



                      </div>
                      :
                      <div className="opacity1">{meta?.totalPages ? t("SEARCHEXPERT.SEARCHRESULTNOTFOUND") : 'Search for expert'}</div>
                  }


                  {/* {expertData?.length > 0 && <Pagination
            limit={10}
            totalCount={expertData?.meta?.totalCount}
            onPaginationButtonClick={onPaginationButtonClick}
          />} */}
                </div>
              </div>
            </div>
          </div>

        </div>



      </section><Footer />
    </>

  );
};

export default CustomerAskTheExpertJsx;