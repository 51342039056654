import React from 'react'

import InputMask from 'react-input-mask';
import Select, { components } from 'react-select';


const OccupationJsx = (props) => {
  const {
    onInputChangeHandler, onOthersButtonClick, editOther,occupationBlock,
    changeOtherState, formError, onTouchHandler,handleChange,responseData
  } = props;
  const isRequired = true

  const information = props?.wholeData




const interestData = [
  {
      "label": "Insurance",
      "value": 1
  },
  {
      "label": "Investment",
      "value": 2
  },
  {
      "label": "Mortgage",
      "value": 3
  },
  {
      "label": "Banking",
      "value": 4
  },
  {
      "label": "Real Estate",
      "value": 5
  }
]

  const occuData = [
    {

      "label": "Select",
      "value": ''
    },
    {
      "label": "Marketing & Sales",
      "value": "Marketing & Sales"
    },
    {
      "label": "Healthcare",
      "value": "Healthcare"
    },
    {
      "label": "Education",
      "value": "Education"
    },
    {
      "label": "Finance & Business",
      "value": "Finance & Business"
    },
    {
      "label": "Administration",
      "value": "Administration"
    },
    {
      "label": "Restaurants",
      "value": "Restaurants"
    },
    {
      "label": "Transportation & Logistics",
      "value": "Transportation & Logistics"
    },
    {
      "label": "Manufacturing & Engineering",
      "value": "Manufacturing & Engineering"
    },
    {
      "label": "Construction & Trades",
      "value": "Construction & Trades"
    },
    {
      "label": "Customer Service",
      "value": "Customer Service"
    },
    {
      "label": "Information Technology",
      "value": "Information Technology"
    },
    {
      "label": "Others",
      "value": "Others"
    }
  ]

  const annualData = [
    {
      "label": "Select",
      "value": ''
    },
    {
      "label": "Less than CAD 55,000",
      "value": "55,000"
    },
    {
      "label": "CAD 55,000 - 110,000",
      "value": "110,000"
    },
    {
      "label": "CAD 110,000 - 165,000",
      "value": "165,000"
    },
    {
      "label": "CAD 165,000 - 220,000",
      "value": "220,000"
    },
    {
      "label": "More than CAD 220,000",
      "value": "220,000+"
    }
  ]

  const netWorthData = [
    {
      "label": "Select",
      "value": ''
    },
    {
      "label": "Less than 1 million",
      "value": "Less than 1 million"
    },
    {
      "label": "1 million - 5 million",
      "value": "1 million - 5 million"
    },
    {
      "label": "5 million - 10 million",
      "value": "5 million - 10 million"
    },
    {
      "label": "10 million - 15 million",
      "value": "10 million - 15 million"
    },
    {
      "label": "More than 15 million",
      "value": "More than 15 million"
    }
  ]

  const annualHouseData = [
    {
      "label": "Select",
      "value": ''
    },
    {
      "label": "Less than CAD 55,000",
      "value": "55,000"
    },
    {
      "label": "CAD 55,000 - 110,000",
      "value": "110,000"
    },
    {
      "label": "CAD 110,000 - 165,000",
      "value": "165,000"
    },
    {
      "label": "CAD 165,000 - 220,000",
      "value": "220,000"
    },
    {
      "label": "More than CAD 220,000",
      "value": "220,000+"
    }
  ]




  const getMuskPattern = (name) =>
    name === 'institutionalContact' || 'customerContact' ? '(999) 999 9999' : 'a9a 9a9'
  return (
    <>
      {!editOther ? <div className="myaccountsection institutedetails martop60">
        <div className="myaccountsectionheading">
          <h3 className="h3_style">Occupational Details</h3>
          <div className="mybtn">
            <button type="button" className="myadbtn" onClick={() => changeOtherState(true)}><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4597 5.8743L13.8341 4.49994L13.8341 4.49992C14.0686 4.26543 14.1858 4.14818 14.2703 4.03599C14.8063 3.32377 14.8063 2.34278 14.2703 1.63057C14.1858 1.51837 14.0686 1.40112 13.8341 1.16663L13.8341 1.16661L13.8341 1.16658C13.5996 0.932098 13.4823 0.814852 13.3701 0.730405C12.6579 0.194357 11.6769 0.194357 10.9647 0.730405C10.8525 0.814852 10.7353 0.932097 10.5008 1.16658L10.5007 1.16661L9.10713 2.56023C9.90404 3.94047 11.0608 5.08825 12.4597 5.8743ZM7.65253 4.01482L2.19046 9.4769C1.7654 9.90196 1.55287 10.1145 1.41314 10.3756C1.2734 10.6367 1.21446 10.9314 1.09657 11.5209L0.647836 13.7645C0.581314 14.0971 0.548053 14.2634 0.642661 14.358C0.737269 14.4526 0.903573 14.4194 1.23618 14.3529H1.23618L3.47983 13.9041C4.06928 13.7862 4.36401 13.7273 4.62511 13.5876C4.8862 13.4478 5.09873 13.2353 5.52379 12.8102L11.0014 7.33267C9.65352 6.48191 8.51135 5.34748 7.65253 4.01482Z" fill="white" />
            </svg></button>
          </div>
        </div>

        <div className="disgrid grid-2">
          <div className="form-fieldinner">
            <label>Occupation</label>
            <div className="fieldans">{information?.occupation}</div>
          </div>
          <div className="form-fieldinner">
            <label>Annual Income</label>
            <div className="fieldans">{information?.annualIncome}</div>
          </div>
        </div>
        <div className="disgrid grid-2">
          <div className="form-fieldinner">
            <label>Interest*</label>

            {responseData && responseData?.interests?.map(item => {
              return (
                <>
                  <div className="fieldans">{item?.institutionCategory?.name}</div>
                </>
              )
            })}
            {/* <div className="fieldans">{information?.interest}</div> */}
          </div>
          <div className="form-fieldinner">
            <label>Networth</label>
            <div className="fieldans">{information?.netWorth}</div>
          </div>
        </div>
        <div className="disgrid grid-2">
          <div className="form-fieldinner">
            <label>Country*</label>
            <div className="fieldans">{information?.occupationCountry}</div>
          </div>
          <div className="form-fieldinner">
            <label>Annual Household Income </label>
            <div className="fieldans">{information?.annualHouseHoldIncome}</div>
          </div>
        </div>

      </div>
        : null}
      { editOther ? <div className="myaccountsection institutedetails martop60">
        <div className="myaccountsectionheading">
          <h3 className="h3_style">Occupational Details</h3>
          <div className="mybtn">
            <button type="button" className="myadbtn" onClick={() => changeOtherState(false)}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
              width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
              preserveAspectRatio="xMidYMid meet">

              <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                fill="#ffffff" stroke="none">
                <path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
              </g>
            </svg></button>
          </div>
        </div>


        <div className="disgrid grid-2">
          <div className="form-fieldinner">
            <div className="input-container form-group">
              <select className="input form-control form-select" onChange={onInputChangeHandler} onBlur={onTouchHandler} name="occupation">
                {occuData?.map((item) => {
                  return (
                    <>
                      <option value={item.value} selected={item.value == information?.occupation} >{item.label}</option>
                    </>
                  )
                })}
              </select>
              <label className="placeholder">Occupation 
              </label>
            </div>
          </div>
          <div className="form-fieldinner">
            <div className="input-container form-group">
              <select className="input form-control form-select" onChange={onInputChangeHandler} onBlur={onTouchHandler} name="annualIncome">
                {annualData?.map((item) => {
                  return (
                    <>
                      <option value={item.value} selected={item.value == information?.annualIncome} >{item.label}</option>
                    </>
                  )
                })}
              </select>
              <label className="placeholder">Annual Income
              </label>
            </div>
          </div>
          <div className="mb-4 input-container form-group">

          </div>
        </div>
        <div className="disgrid grid-2">

          <div className="form-fieldinner">
            <div className="input-container form-group">
            <Select className="mb-4 form-container form-group multiselect"
                                    closeMenuOnSelect={false}
                                    classNamePrefix="multicustomselect"
                                    isMulti
                                    options={interestData}
                                    onChange={(e) => handleChange(e, 'interest')}

                                    value={information['interest']}
                                    components={{
                                        Input: (props) => {
                                            const { value } = props

                                            return <>
                                                <label className={`multiplaceholer ${(information['interest']?.length > 0 || value !== '') ? 'active' : ''}`}>Interest*
                                                                    {<span className="plantooltip custooltip" title="" > <div className="tooltipcontent">Interest*</div></span>}
                                                </label>

                                                <components.Input {...props} className={"mb-4 input-container form-group"} >
                                                    {props.children}
                                                </components.Input>
                                            </>
                                        },
                                        Option: (props) => {
                                            const { isSelected } = props

                                            return (
                                                <div>
                                                    <components.Option {...props}>
                                                        <input
                                                            type="checkbox"
                                                            checked={isSelected}
                                                            onChange={() => null}
                                                        />
                                                        {props.children}
                                                    </components.Option>
                                                </div>
                                            );
                                        }
                                    }}

                                />
            </div>
          </div>

          <div className="form-fieldinner">
            <div className="form-fieldinner">
              <div className="input-container form-group">
                <select className="input form-control form-select" onChange={onInputChangeHandler} onBlur={onTouchHandler} name="netWorth">
                  {netWorthData?.map((item) => {
                    return (
                      <>
                        <option value={item.value} selected={item.value == information?.netWorth} >{item.label}</option>
                      </>
                    )
                  })}
                </select>
                <label className="placeholder">Networth 
              </label>
              </div>
            </div>
          </div>
        </div>
        <div className="disgrid grid-2">
          <div className="form-fieldinner">
            <div className="input-container form-group">
              <input type="text" disabled className={
                isRequired && formError["country"]
                  ? "input form-control  is-invalid"
                  : "input form-control"
              } placeholder=" " name="country" maxLength={50} value={information.country} onChange={onInputChangeHandler} onBlur={onTouchHandler} />
              <label className="placeholder">Country</label>
              <span className="text-danger">
                {isRequired &&
                  formError["country"] &&
                  (formError["country"] === "req"
                    ? "country No is required!"
                    : "Please enter a proper country!")}
              </span>
            </div>
          </div>
          <div className="form-fieldinner">
            <div className="input-container form-group">
              <select className="input form-control form-select" onChange={onInputChangeHandler} onBlur={onTouchHandler} name="annualHouseHoldIncome">
                {annualHouseData?.map((item) => {
                  return (
                    <>
                      <option value={item.value} selected={item.value == information?.annualHouseHoldIncome} >{item.label}</option>
                    </>
                  )
                })}
              </select>
              <label className="placeholder">Annual Household Income 
              </label>
            </div>
          </div>
        </div>


        <button type="button" className="ctaonebutton widthhalf" disabled={occupationBlock} onClick={() => onOthersButtonClick()}>Save</button>
      </div> : null}

    </>
  );
};

export default OccupationJsx;
