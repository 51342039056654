import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next";
import realestateicon from "../../../../assets/images/realestateicon.svg";
import insurenceicon from "../../../../assets/images/insurenceicon.svg";
import mortgageicon from "../../../../assets/images/mortgageicon.svg";
import bankingicon from "../../../../assets/images/bankingicon.svg";
import investmenticon from "../../../../assets/images/investmenticon.svg";
import realestateicontwo from "../../../../assets/images/realestateicontwo.svg";
import insurenceicontwo from "../../../../assets/images/insurenceicontwo.svg";
import mortgageicontwo from "../../../../assets/images/mortgageicontwo.svg";
import bankingicontwo from "../../../../assets/images/bankingicontwo.svg";
import investmenticontwo from "../../../../assets/images/investmenticontwo.svg";
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import videoimage from "../../../../assets/images/videoimage.svg";
import videopop from "../../../../assets/images/videopop.svg";
import { Footer } from '../../../common/footer/footer';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import AOS from 'aos';
import Sidebar from "../sidebar/sidebar.js";
import 'aos/dist/aos.css';

export const getIconOne = (label) => {
    if (label === 'Real Estate')
        return realestateicon
    else if (label === 'Mortgage')
        return mortgageicon
    else if (label === 'Insurance')
        return insurenceicon
    else if (label === 'Banking')
        return bankingicon
    else if (label === 'Investment')
        return investmenticon
    else return ''
}

const CustomerAskTheExpertJsx = (props) => {
    const { t } = useTranslation();
    const {
        categoryData,
        onCategoryClick,
        categoryName,
        subCategoryData,
        onSubcategoryClick,
        subCategoryName,
        questionAsked,
        onTextAreaChange,
        onQuestionClick,
        onSubmitButtonClick,
        expertQuestions,
        onclickFocus
    } = props;

    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    }, [])

    const getIconTwo = (label) => {
        if (label === 'Real Estate')
            return realestateicontwo
        else if (label === 'Mortgage')
            return mortgageicontwo
        else if (label === 'Insurance')
            return insurenceicontwo
        else if (label === 'Banking')
            return bankingicontwo
        else if (label === 'Investment')
            return investmenticontwo
        else return ''
    }

    return (
        <>
        <section class="entiredashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-3">


              <div className="dashboardleftpanel martop40">

                <div className="dashboardlinkpop martop40">
                  <Sidebar />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-9">
<div className="dashboardrightpanel martop40 mb-5">
            <div className="dashboardrightpannerinner">
                  <div className="dashboardrightpannerinnerleft">
                    <h2 className="h2_style font-weight-600">Ask the Experts</h2>
                    <h5 className="h5_style">We understand that financial matters can be complex and overwhelming. 
That’s why we are dedicated to providing you with verified answers that truly make a difference. 
Ask questions and get tailored responses from certified experts at no cost & no obligations. </h5>
                   
                  </div>
                  <div className="dashboardrightpannerinnerright">
                    <div className="signout">
                      <a href="#logout">
                        <img src={Sign_out_squre} alt={''} /><span className="title">Logout</span>
                      </a>
                    </div>
                    <div id="logout" class="modal-window">
                      <div>
                        <a  title="Close" class="modal-close">Close</a>
                        <div className="modal-header">

                        </div><div className="modal-body mb-4">
                          <div className="modal-body-inner">
                            <h2 className="h2_style text-center">Are you sure you want to
Logout ?</h2>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="ctaonebutton"> Save</button>
                          <button type="button" className="ctatwobutton"> Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            <section className="asktheexpertcategorysection" id="ask">
               
                    <div className="asktheexpertcategorysectioninner asktheexpertcategorysectioninnerline">
                      
                        <div className="catstart clearfix" data-aostest="fade-up-right">
                            <div className={`categoryonesetfirst ${categoryName && 'categoryoneset'}`}>
                                <div className="disgrid grid-3">
                                    <div className="categoryonesetone" >
                                        <p>{t("ASKTHEEXPERT.SELECT")}</p>
                                        <h4>{t("ASKTHEEXPERT.CATEGORY")}</h4>
                                        <p className="categoryonesetonetext"><b>{t("ASKTHEEXPERT.TEXT1")}</b> {t("ASKTHEEXPERT.TEXT2")}</p>
                                    </div>
                                    {
                                        categoryData?.map(catDT => {
                                            return <div className={`categoryonesettwo ${categoryName === catDT?.name ? 'active' : ''}`} onClick={() => onCategoryClick(catDT?.institutionCategoryId, catDT?.name)}>
                                                <div className={`iconone ${categoryName === catDT?.name ? 'active' : ''}`} ><img src={getIconOne(catDT.name)} alt={''} /></div>
                                                <div className="icontwo"><img src={getIconTwo(catDT.name)} alt={''} /></div>
                                                <h5>{catDT.name}</h5>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        {categoryName && <div className="catstart clearfix" data-aostest="fade-up-left">
                            <div className={`categorytwosetfirst ${subCategoryName && 'categorytwoset'}`}>

                                <div className="disgrid grid-4" id="askts" onClick={(e) => onclickFocus("askQus")}>
                                    <div className="categoryonesetone anotherexp">
                                        <p>{t("ASKTHEEXPERT.SELECT2")}</p>
                                        <h4>{t("ASKTHEEXPERT.CATEGORY2")} </h4>
                                        <p className="categoryonesetonetext"><b>{t("ASKTHEEXPERT.TEXT3")}</b> {t("ASKTHEEXPERT.TEXT4")}</p>
                                    </div>
                                    {
                                        subCategoryData?.map(subCatDT => {
                                            return <div id="askqw" className={`categoryonesettwo categoryonesetwidth ${subCategoryName === subCatDT?.name ? 'active' : ''}`} onClick={() => onSubcategoryClick(subCatDT.institutionCategoryId, subCatDT.name)}>
                                                <div className="iconone"><img src={`/${(subCatDT?.name).replace(/\s/g,'')}.png`} alt={''} /></div>
                                                <div className="icontwo"><img src={`/${(subCatDT?.name).replace(/\s/g,'')}.png`} alt={''} /></div>
                                                <h5>{subCatDT.name}</h5>
                                            </div>
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                        }
                        {subCategoryName && <div id="askQus">
                            <div className="catstart clearfix" data-aostest="fade-up-up">
                                <div className="categorythreeset">
                                    <div className="categoryonesetone">

                                        <h4>{t("ASKTHEEXPERT.QUESTIONHEADING")}</h4>
                                        <p className="categoryonesetonetext"><b>{t("ASKTHEEXPERT.QUESTIONTEXT1")} </b>
                                            {t("ASKTHEEXPERT.QUESTIONTEXT2")}</p>

                                    </div>
                                    
                                    <AwesomeSlider>
                                        {
                                            expertQuestions.length > 0 ? expertQuestions.map(que => {
                                                return <div onClick={() => onQuestionClick(que)}>{que.question}</div>

                                            }) :

                                                <div>No Question Found</div>
                                        }
                                    </AwesomeSlider>
                                   
                                </div>
                            </div>
                            <div className="catstart clearfix" data-aostest="fade-up-bottom">
                                <div className="categoryfourset">
                                    <div className="categoryonesetone">
                                        <p>{t("ASKTHEEXPERT.QUESTIONHEADING1")} </p>
                                        <h4>{t("ASKTHEEXPERT.QUESTIONTEXT3")} </h4>
                                        <p className="categoryonesetonetext"><b>{t("ASKTHEEXPERT.QUESTIONTEXT4")}</b> {t("ASKTHEEXPERT.QUESTIONTEXT5")}</p>
                                    </div>
                                 
                                </div>
                            </div>
                            <div className="textarecs mt-5" data-aostest="fade-bottom">
                                <div className="input-container form-group">
                                    <textarea className="input form-control textareaform" value={questionAsked} name="message" onChange={onTextAreaChange} placeholder=" "></textarea>
                                    <div className="placeholder">This is what you’ll be asking the experts.... </div>
                                </div>
                            </div>
                            <div className="mainexpform mb-5 mt-5" data-aostest="fade-up-right">
                                <h4 className="text-center">Sit tight! Our experts know exactly what you need. </h4>
                                <h5 className="text-center">Just enter the details below. Get precise, valuable & objective responses.  </h5>
                                <div className="text-center">
                                    {/* <Recaptcha
                                        key={Math.random()}
                                        sitekey="6LdcBSEgAAAAAIs7aSdegby02xHlo_C8BNH6z-tX"
                                        onChange={onChange}
                                        /> */}
                                </div>
                                <div className="text-center">
                                    <button type="button" disabled={!questionAsked} className="ctaonebutton widthhalf mt-3" onClick={onSubmitButtonClick}>Submit</button>
                                </div>
                            </div>
                        </div>}
                    </div>
               
            </section>
            
            </div>
            </div>
            </div>
            </div>
            </section>
            <Footer />

        </>

    );
};

export default CustomerAskTheExpertJsx;