import React, { Component } from 'react';
import {
    emailRegex, alphaNumericRegex, websiteRegex, alphabetWithSpaceRegex, nameRegex,
    postalCodeRegex, getRoute, getProperDateMonth
} from '../../../shared/helper.js';
import { Loader } from '../../common/loader/loader.jsx';
import InstitutionalRegistrationJSX from './institutionalRegistration.jsx';
import {
    baseURL, uploadURL
} from "../../../constants/applicationConstants.js";
import axios from 'axios'
import signUpjson from "./institutionalRegistration.json";
import unverifyjson from "./unverified.json";

import facebook from "../../../assets/images/facebook.svg";
import twitter from "../../../assets/images/twitter.svg";
import insta from "../../../assets/images/insta.svg";
import linkedin from "../../../assets/images/linkedin.svg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class InstitutionalRegistration extends Component {
    constructor(props) {
        const verificationInfo = localStorage.getItem('verificationStatus');

        super(props);
        this.state = {
            formData: {
                institutionName: "",
                legalInstitutionName: "",
                institutionEmail: "",
                segment: "",
                category: null,
                sizeOfInstitution: "",
                dateOfIncorporation: null,
                firstName: "",
                lastName: "",
                email: "",
                confirmInstitutionEmail: "",
                phone: "",
                postalCode: "",
                province: "",
                city: "",
                country: "",
                street: "",

                billing_postalCode: "",
                billing_province: "",
                billing_city: "",
                billing_country: "",
                billing_street: "", licenseNo: "",
                regulatory: "",
                institutionalContact: "",
                customerContact: "",
                website: "",
                facebook: "",
                insta: "",
                twitter: "",
                linkedin: "",
                tempCat: null
            },
            isProcessing: false,
            formError: {},
            formSubmitButtonDisable: true,
            isLoading: false,
            fields: verificationInfo == "unVerified" ? unverifyjson : signUpjson,
            _URL: window.URL || window.webkitURL,
            imageName: null,
            imageUrl: "",
            fileInfo: null,
            profileImagePreviewUrl: null,
            isEditMode: false,
            image: ""
        };

    }



    setDefaultValues = (data) => {
        const verificationInfo = localStorage.getItem('verificationStatus');

        const { name, legalName, email, institutionSegmentId, instituteSizeId, primaryInstituteUser, postalCode,
            stateCode, city, countryCode, address, regulatoryAssociation, contactNumber, customerCareNumber, website,
            socialMedia, instituteLicenses, instituteCategories, incorporationDate, imageUrl, billingAddress } = data;

        const { firstName, lastName } = primaryInstituteUser || {};


        const { facebookUrl, instagramUrl, linkedinUrl, twitterUrl } = socialMedia;

        const date = incorporationDate && incorporationDate.split('-');

        this.setState({
            formData: {
                institutionName: name,
                legalInstitutionName: legalName,
                institutionEmail: email,
                confirmInstitutionEmail: email,
                segment: institutionSegmentId,
                tempCat: instituteCategories,
                sizeOfInstitution: instituteSizeId,
                dateOfIncorporation: date && {
                    day: date && parseInt(date[2]),
                    month: date && parseInt(date[1]),
                    year: date && parseInt(date[0])
                },
                firstName: firstName,
                lastName: lastName,
                email: primaryInstituteUser && primaryInstituteUser.email,
                phone: primaryInstituteUser && primaryInstituteUser.contactNumber,
                postalCode: postalCode,
                province: stateCode,
                city: city,
                country: countryCode,
                street: address,
                // billing
                billing_postalCode: billingAddress?.postalCode,
                billing_province: billingAddress?.stateCode,
                billing_city: billingAddress?.city,
                billing_country: billingAddress?.countryCode,
                billing_street: billingAddress?.address,
                licenseNo: instituteLicenses?.map(x => x.licenseNumber)?.join(','),
                regulatory: regulatoryAssociation,
                institutionalContact: contactNumber,
                customerContact: customerCareNumber,
                website: website,
                facebook: facebookUrl,
                insta: instagramUrl,
                twitter: twitterUrl,
                linkedin: linkedinUrl
            },
            isLoading: false,
            isEditMode: true,
            imageUrl: imageUrl && imageUrl,
            image: imageUrl && imageUrl,
        }, () => {
            this.getSegments();

            verificationInfo == "unVerified" ? this.isFromSubmissionBlockUnverify() : verificationInfo == "unVerified" ? this.isFromSubmissionBlockUnverify() : verificationInfo == "unVerified" ? this.isFromSubmissionBlockUnverify() : this.isFromSubmissionBlock();

        })
    }
    // verificationInfo == "unVerified" ? this.isFromSubmissionBlockUnverify() :  verificationInfo == "unVerified" ? this.isFromSubmissionBlockUnverify() : this.isFromSubmissionBlock();

    componentDidMount = () => {

        this.setState({ isLoading: true })
        let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
        // if (!token) {
        //     this.props.history.push("/");
        // }
        let instituteId = sessionStorage.getItem("instituteId") && JSON.parse(sessionStorage.getItem("instituteId"))

        let verifyInstituteID = localStorage.getItem('VerifyInstituteID');

        if (verifyInstituteID) {
            axios.get(`${baseURL}/institute/${verifyInstituteID}/details`, {
                headers: {
                    'x-access-token': token
                }
            }).then(res => {
                this.setDefaultValues(res.data.data)
            })
        }
        else if (token && instituteId) {
            axios.get(`${baseURL}/institute/${instituteId}?tokenValidate=${token}`).then(res => {
                this.setDefaultValues(res.data.data);
            })
        }
        else {
            this.getSegments();
        }

        axios.get(`${baseURL}/countries`).then(res => {
            this.getUpdateJSON('country', res.data.data);
            this.getUpdateJSON('billing_country', res.data.data);

        })

        axios.get(`${baseURL}/states`).then(res => {
            this.getUpdateJSON('province', res.data.data);
            this.getUpdateJSON('billing_province', res.data.data);

        })

        axios.get(`${baseURL}/institutionSizes`).then(res => {
            this.getUpdateJSON('sizeOfInstitution', res.data.data);
            this.setState({ isLoading: false })
        })
    }

    getSegments = () => {
        const verificationInfo = localStorage.getItem('verificationStatus');

        axios.get(`${baseURL}/instituteSegments`).then(res => {
            this.getUpdateJSON('segment', res.data.data);
            let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
            let instituteId = sessionStorage.getItem("instituteId") && JSON.parse(sessionStorage.getItem("instituteId"))
            instituteId = localStorage.getItem('VerifyInstituteID') ?? instituteId

            if (token && instituteId) {
                const category = res.data.data.filter(item => item.institutionSegmentId === this.state.formData.segment)[0]?.institutionCategories;
                this.getUpdateJSON('category', category);

                let tempArr = [];

                category?.forEach(cat => {
                    this.state.formData.tempCat?.forEach(abc => {
                        if (cat.institutionCategoryId === abc.institutionCategoryId)
                            tempArr.push({ label: cat.name, value: cat.institutionCategoryId });
                    })
                })
                this.setState({ formData: { ...this.state.formData, category: [...tempArr] } }, () => verificationInfo == "unVerified" ? this.isFromSubmissionBlockUnverify() : this.isFromSubmissionBlock())
            }
        })
    }

    getUpdateJSON = (label, values) => {
        let dropdownOptions = [{ label: 'Select', value: null }];

        if (label === 'segment') {
            values.forEach(value => {
                dropdownOptions.push({ label: value.name, value: value.institutionSegmentId, category: value.institutionCategories })
            })
        }
        else if (label === 'category') {
            dropdownOptions = []
            values?.forEach(value => {
                dropdownOptions.push({ label: value.name, value: value.institutionCategoryId })
            })
        }
        else if (label === 'sizeOfInstitution') {
            values.forEach(value => {
                dropdownOptions.push({ label: value.size, value: value.institutionSizeId })
            })
        }
        else if (label === 'country' || label === "billing_country") {
            values.forEach(value => {
                dropdownOptions.push({ label: value.name, value: value.countryCode })
            })
        }
        else if (label === 'province' || label === "billing_province") {
            values.forEach(value => {
                dropdownOptions.push({ label: value.name, value: value.stateCode })
            })
        }

        let tempArr = [...this.state.fields];
        tempArr.forEach((data, index) => {
            if (data.name === label)
                tempArr[index] = { ...tempArr[index], options: dropdownOptions };
            else {
                data.fields?.forEach((data1, index) => {
                    if (data1.name === label)
                        data.fields[index] = { ...data.fields[index], options: dropdownOptions };
                })
            }
        })
        this.setState({ fields: tempArr })
    }

    handleChange = (e, item) => {
        const verificationInfo = localStorage.getItem('verificationStatus');

        if (item.name === 'segment') {
            this.onSegmentChange(item, e.value)
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [item.name]: e.value,
                        category: []
                    },
                    formError: {
                        ...this.state.formError,
                        [item.name]: "regx",
                    },
                },
                () => verificationInfo == "unVerified" ? this.isFromSubmissionBlockUnverify() : this.isFromSubmissionBlock()
            );
        }
        else if (item.name === 'category') {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [item.name]: e,
                    },
                    formError: {
                        ...this.state.formError,
                        [item.name]: "regx",
                    },
                },
                () => verificationInfo == "unVerified" ? this.isFromSubmissionBlockUnverify() : this.isFromSubmissionBlock()
            );
        }
        else {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [item.name]: e.value
                    },
                    formError: {
                        ...this.state.formError,
                        [item.name]: "regx",
                    },
                },
                () => verificationInfo == "unVerified" ? this.isFromSubmissionBlockUnverify() : this.isFromSubmissionBlock()
            );
        }
    }

    onSegmentChange = (items, value) => {
        const category1 = items?.options?.filter(item => item.value == value)[0].category;
        this.getUpdateJSON('category', category1);
    }

    onInputChangeHandler = (e, item = '') => {
        const verificationInfo = localStorage.getItem('verificationStatus');

        let name = e.target.name;
        let value = e.target.value;
        if (value) {
            if (!this.inputRegexValidation(name, value)) {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "regx",
                        },
                    },
                    () => verificationInfo == "unVerified" ? this.isFromSubmissionBlockUnverify() : this.isFromSubmissionBlock()
                );
            } else {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "",
                        },
                    },
                    () => verificationInfo == "unVerified" ? this.isFromSubmissionBlockUnverify() : this.isFromSubmissionBlock()
                );
            }
        } else {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [name]: value,
                    },
                    formError: {
                        ...this.state.formError,
                        [name]: "",
                    },
                },
                () => verificationInfo == "unVerified" ? this.isFromSubmissionBlockUnverify() : this.isFromSubmissionBlock()
            );
        }
    };


    onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value === "") {
            this.setState({
                formError: {
                    ...this.state.formError,
                    [name]: "req",
                },
            });
        } else {
            if (!this.inputRegexValidation(name, value)) {
                this.setState({
                    formError: {
                        ...this.state.formError,
                        [name]: "regx",
                    },
                });
            }
        }
    };

    getProperPhoneNumber = (value) => {

        if (!isNaN(value)) {
            return value
        }

        const number = value.split(' ');
        return number[0].replace('(', '').replace(')', '') + number[1] + number[2]
    }

    inputRegexValidation = (name, value) => {
        if (name === 'postalCode' || name === 'billing_postalCode')
            return postalCodeRegex.test(value);
        if (name === 'institutionName' || name === 'licenseNo')
            return alphaNumericRegex.test(value);
        else if (name === 'legalInstitutionName' || name === 'regulatory' || name === 'city' || name === 'billing_city')
            return alphabetWithSpaceRegex.test(value);
        else if (name === 'firstName' || name === 'lastName' || name === 'city' || name === 'billing_city')
            return nameRegex.test(value);
        else if (name === "email" || name === "institutionEmail" || name === "confirmInstitutionEmail")
            return emailRegex.test(value);
        else if (name === "phone" || name === "institutionalContact" || name === "customerContact")
            return !this.getProperPhoneNumber(value).includes('_');
        else if (name === "website")
            return websiteRegex.test(value);
        else if (name === 'facebook')
            return value.includes('facebook')
        else if (name === 'insta')
            return value.includes('instagram')
        else if (name === 'twitter')
            return value.includes('twitter')
        else if (name === 'linkedin')
            return value.includes('linkedin')

        return true;
    };

    isFromSubmissionBlock = () => {
        let { institutionName, legalInstitutionName, institutionEmail, confirmInstitutionEmail, segment, category, tempCat, sizeOfInstitution,
            dateOfIncorporation, firstName, lastName, email, phone, postalCode, street, province, city, country, billing_postalCode, billing_street, billing_province, billing_city, billing_country, institutionalContact, website } = this.state.formData;

        if (
            !this.inputRegexValidation("institutionEmail", institutionEmail) ||
            !this.inputRegexValidation("confirmInstitutionEmail", confirmInstitutionEmail)

        ) {
            this.setState({
                formSubmitButtonDisable: true,
            });
        } else {
            if ((this.inputRegexValidation("institutionEmail", institutionEmail) && this.inputRegexValidation("confirmInstitutionEmail", confirmInstitutionEmail))
                && (institutionEmail === confirmInstitutionEmail)) {
                this.setState({
                    formSubmitButtonDisable: false,
                    formError: {
                        ...this.state.formError,
                        confirmInstitutionEmail: "",
                    }
                });
            } else {
                this.setState({
                    formSubmitButtonDisable: true,
                    formError: {
                        ...this.state.formError,
                        confirmInstitutionEmail: "notMatch",
                    }
                });
                return;
            }
        }
        if (institutionName && legalInstitutionName && institutionEmail && confirmInstitutionEmail && segment && (category || tempCat) && sizeOfInstitution &&
            dateOfIncorporation?.day && firstName && lastName && email && phone && postalCode && street &&
            province && city && country && billing_postalCode && billing_street &&
            billing_province && billing_city && billing_country && institutionalContact && website) {

            if (
                this.inputRegexValidation("institutionName", institutionName) &&
                this.inputRegexValidation("legalInstitutionName", legalInstitutionName) &&
                this.inputRegexValidation("institutionEmail", institutionEmail) &&
                this.inputRegexValidation("confirmInstitutionEmail", confirmInstitutionEmail) &&
                this.inputRegexValidation("segment", segment) &&
                this.inputRegexValidation("category", category) &&
                this.inputRegexValidation("sizeOfInstitution", sizeOfInstitution) &&
                this.inputRegexValidation("firstName", firstName) &&
                this.inputRegexValidation("lastName", lastName) &&
                this.inputRegexValidation("email", email) &&
                this.inputRegexValidation("phone", phone) &&
                this.inputRegexValidation("postalCode", postalCode) &&
                this.inputRegexValidation("street", street) &&
                this.inputRegexValidation("province", province) &&
                this.inputRegexValidation("country", country) &&
                this.inputRegexValidation("city", city) &&


                this.inputRegexValidation("postalCode", billing_postalCode) &&
                this.inputRegexValidation("street", billing_street) &&
                this.inputRegexValidation("province", billing_province) &&
                this.inputRegexValidation("country", billing_country) &&
                this.inputRegexValidation("city", billing_city) &&
                this.inputRegexValidation("institutionalContact", institutionalContact) &&
                this.inputRegexValidation("website", website)
            ) {
                this.setState({
                    formSubmitButtonDisable: false,
                });
            } else {
                this.setState({
                    formSubmitButtonDisable: true,
                });
            }
        } else {
            this.setState({
                formSubmitButtonDisable: true,
            });
        }
    };

    isFromSubmissionBlockUnverify = () => {
        let { institutionName, institutionEmail, confirmInstitutionEmail, segment, category, tempCat,
            postalCode, street, province, city, country, website } = this.state.formData;

        if (
            !this.inputRegexValidation("institutionEmail", institutionEmail) ||
            !this.inputRegexValidation("confirmInstitutionEmail", confirmInstitutionEmail)

        ) {
            this.setState({
                formSubmitButtonDisable: true,
            });
        } else {
            if ((this.inputRegexValidation("institutionEmail", institutionEmail) && this.inputRegexValidation("confirmInstitutionEmail", confirmInstitutionEmail))
                && (institutionEmail === confirmInstitutionEmail)) {
                this.setState({
                    formSubmitButtonDisable: false,
                    formError: {
                        ...this.state.formError,
                        confirmInstitutionEmail: "",
                    }
                });
            } else {
                this.setState({
                    formSubmitButtonDisable: true,
                    formError: {
                        ...this.state.formError,
                        confirmInstitutionEmail: "notMatch",
                    }
                });
                return;
            }
        }
        if (institutionName && institutionEmail && confirmInstitutionEmail && segment && (category || tempCat) && postalCode && street &&
            province && city && country && website) {

            if (
                this.inputRegexValidation("institutionName", institutionName) &&
                this.inputRegexValidation("institutionEmail", institutionEmail) &&
                this.inputRegexValidation("confirmInstitutionEmail", confirmInstitutionEmail) &&
                this.inputRegexValidation("segment", segment) &&
                this.inputRegexValidation("category", category) &&

                this.inputRegexValidation("postalCode", postalCode) &&
                this.inputRegexValidation("street", street) &&
                this.inputRegexValidation("province", province) &&
                this.inputRegexValidation("country", country) &&
                this.inputRegexValidation("city", city) &&



                this.inputRegexValidation("website", website)
            ) {
                this.setState({
                    formSubmitButtonDisable: false,
                });
            } else {
                this.setState({
                    formSubmitButtonDisable: true,
                });
            }
        } else {
            this.setState({
                formSubmitButtonDisable: true,
            });
        }
    };

    onDateChange = (date) => {
        const verificationInfo = localStorage.getItem('verificationStatus');

        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    dateOfIncorporation: date,
                }
            },
            () => verificationInfo == "unVerified" ? this.isFromSubmissionBlockUnverify() : this.isFromSubmissionBlock()
        );
    }

    getSocialImage = (item) =>
        item.name === 'facebook' ? facebook : item.name === 'insta' ? insta : item.name === 'twitter' ? twitter : linkedin

    onNextButtonClick = () => {
        this.setState({
            isLoading: true
        })
        let formData1 = new FormData();

        if (this.state.fileInfo) {
            formData1.append('image', this.state.fileInfo)
            formData1.append('imageCategory', "institute")

            axios.post(`${uploadURL}/upload-file`, formData1).then(resp => {
                if (resp.status === 200) {
                    this.setState({ image: resp.data.filename })
                    setTimeout(() => {
                        this.onNextButtonClick1()
                    }, 2000)
                }
            }).catch(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
        else this.onNextButtonClick1();
    };
    backAdmin = () => {
        const adminToken = sessionStorage.getItem('adminOldToken');


        sessionStorage.setItem("token", JSON.stringify(adminToken));
        sessionStorage.removeItem("adminOldToken")
        localStorage.removeItem("verificationStatus")

        //sessionStorage.removeItem("loginData")
        sessionStorage.removeItem("expertId")
        sessionStorage.removeItem("instituteId")
        localStorage.removeItem("slugSet")
        window.location.reload()

        // this.props.history.push("/admin/manageUnverifiedInstitute");

    }


    onNextButtonClick1 = () => {
        this.setState({
            isLoading: true
        })

        let image = this.state.image;

        const { institutionName, legalInstitutionName, institutionEmail, confirmInstitutionEmail, segment, category, sizeOfInstitution,
            dateOfIncorporation, firstName, lastName, billing_city, billing_postalCode, billing_street, billing_province, billing_country, city, postalCode, street, province, country, email, phone,
            regulatory, institutionalContact, customerContact, website, licenseNo, facebook, insta, twitter, linkedin } = this.state.formData;

        const loginData = JSON.parse(sessionStorage.getItem('loginData'));

        const status = localStorage.getItem('verificationStatus');

        const payloadData1 = {
            "status": status == 'unVerified' ? 'unverified' : '',
            "registerdBy": loginData?.userId ?? null,
            "name": institutionName,
            "email": institutionEmail,
            "confirmEmail": confirmInstitutionEmail,
            "legalName": legalInstitutionName || null,
            "institutionSegmentId": segment,
            "instituteSizeId": sizeOfInstitution || null,
            "incorporationDate": dateOfIncorporation && `${dateOfIncorporation?.year}-${getProperDateMonth(dateOfIncorporation?.month)}-${getProperDateMonth(dateOfIncorporation.day)}`,
            "logoPath": "./logo/manitoba.png",
            "address": street,
            "city": city,
            "stateCode": province,
            "countryCode": country,
            "postalCode": postalCode.toString(),
            "regulatoryAssociation": regulatory,
            "contactNumber": this.getProperPhoneNumber(institutionalContact) || null,
            "customerCareNumber": customerContact ? this.getProperPhoneNumber(customerContact) : null,
            "website": website,
            "licenseNumber": licenseNo,
            "instituteCategories": category?.map(cat => cat.value),
            "instagramUrl": insta,

            "facebookUrl": facebook,
            "twitterUrl": twitter,
            "linkedinUrl": linkedin,
            "primaryContactPerson": {
                "firstName": firstName || null,
                "lastName": lastName || null,
                "email": email || null,
                "contactNumber": this.getProperPhoneNumber(phone) || null
            },
            "billingAddress": {
                "address": billing_street || null,
                "city": billing_city || null,
                "stateCode": billing_province || null,
                "countryCode": billing_country || null,
                "postalCode": billing_postalCode && billing_postalCode.toString() || null,
            },
            imageUrl: image
        }


        let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
        let instituteId = sessionStorage.getItem("instituteId") && JSON.parse(sessionStorage.getItem("instituteId"))

        const payloadData = image ? { ...payloadData1, imageUrl: image } : { ...payloadData1 }

        let verifyInstituteID = localStorage.getItem('VerifyInstituteID');

        if (verifyInstituteID) {
            axios.put(`${baseURL}/institute/${verifyInstituteID}/verifyAccount`, { ...payloadData, status: 'verified' }, {
                headers: {
                    'x-access-token': token
                }
            }).then(res => {
                if (res.data.success) {
                    localStorage.removeItem('VerifyInstituteID');
                    toast.success('Account Verified');
                    setTimeout(() => {
                        localStorage.removeItem('VerifyInstituteID');
                        localStorage.removeItem("verificationStatus")
                        this.props.history.push('./admin/manageUnverifiedInstitute')
                        this.backAdmin()

                    }, 1000)
                    window.scrollTo(0, 0);
                    this.setState({
                        isLoading: false
                    })
                }
                else {
                    toast.error(res.data?.error.message)
                    this.setState({
                        isLoading: false
                    })
                }

            }).catch(err => {
                toast.error(err?.response.data?.error.message)
                this.setState({
                    isLoading: false
                })
            })
        } else if (instituteId) {
            let flowType = localStorage.getItem('flowName') ?? ''
            axios.put(`${baseURL}/institute/${instituteId}`, {
                "tokenValidate": token, ...payloadData
            }).then(res => {
                if (res.data.success) {
                    sessionStorage.setItem("token", JSON.stringify(res.data?.data?.tokenValidate));
                    sessionStorage.setItem("email", JSON.stringify(this.state.formData.institutionEmail));
                    if (!flowType) {
                        localStorage.setItem('flowName', 'institutional')
                        localStorage.setItem('flowType', 'institute')
                        flowType = 'institutional'
                    }
                    flowType = (status == 'unVerified' ? 'unVerified' : flowType)
                    this.props.history.push(getRoute(flowType, 'institutionalRegistration'));
                    window.scrollTo(0, 0);
                    this.setState({
                        isLoading: false
                    })
                }
                else {
                    toast.error(res.data.error.message)
                    this.setState({
                        isLoading: false
                    })
                }

            }).catch(err => {
                toast.error(err.response.data.error.message)
                this.setState({
                    isLoading: false
                })
            })
        }
        else {
            let flowType = localStorage.getItem('flowName') ?? ''
            axios.post(`${baseURL}/institute`, payloadData).then(res => {
                if (res.data.success) {
                    sessionStorage.setItem("token", JSON.stringify(res.data?.data?.tokenValidate));
                    sessionStorage.setItem("email", JSON.stringify(this.state.formData.institutionEmail));
                    sessionStorage.setItem("instituteId", JSON.stringify(res.data?.data?.instituteId));
                    if (!flowType) {
                        localStorage.setItem('flowName', 'institutional')
                        localStorage.setItem('flowType', 'institute')
                        flowType = 'institutional'
                    }
                    flowType = (status == 'unVerified' ? 'unVerified' : flowType)

                    this.props.history.push(getRoute(flowType, 'institutionalRegistration'));
                    window.scrollTo(0, 0);
                    this.setState({
                        isLoading: false
                    })
                    if (status == 'unVerified') {
                        this.backAdmin()
                    }
                }
                else {
                    toast.error(res.data?.error.message)
                    this.setState({
                        isLoading: false
                    })
                }

            }).catch(err => {
                toast.error(err?.response.data?.error.message)
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    validFileName = (name) => name.includes('.jpeg') || name.includes('.jpg') || name.includes('.png');

    onFileChange = (e) => {
        const verificationInfo = localStorage.getItem('verificationStatus');

        if (e.target.files[0]?.size) {
            if (e.target.files[0]?.size < 512000) {
                if (e.target.files[0] && this.validFileName(e.target.files[0].name)) {
                    // if ((file = e.target.files[0])) {
                    //     img = new Image();
                    //     img.onload = function () {
                    //         if (this.height > 800) {
                    //             toast.error("big height")
                    //         }
                    //         else{
                    //             toast.error("small height")
                    //             img.src = this.state._URL.createObjectURL(file);
                    //             this.setState({ fileInfo: e.target.files[0] })
                    //             this.setState({ profileImagePreviewUrl: URL.createObjectURL(e.target.files[0]) })
                    //             this.setState({ imageName: e.target.files[0].name },()=> this.isFromSubmissionBlock())
                    //         }
                    //     };
                    //     img.onerror = function () {
                    //         toast.error("not a valid file: " + file.type);
                    //     };
                    // }
                    this.setState({ fileInfo: e.target.files[0] }, () => verificationInfo == "unVerified" ? this.isFromSubmissionBlockUnverify() : this.isFromSubmissionBlock())
                    this.setState({ profileImagePreviewUrl: URL.createObjectURL(e.target.files[0]) })
                    this.setState({ imageName: e.target.files[0].name })
                }
                else
                    toast.error('.jpeg/.jpg/.png format required')
            }
            else
                toast.error('File should be less than 500 KB.')
        }
    }

    render() {
        return (
            <>
                <InstitutionalRegistrationJSX
                    field={this.state.fields}
                    formData={this.state.formData}
                    onInputChangeHandler={this.onInputChangeHandler}
                    onTouchHandler={this.onTouchHandler}
                    formSubmitButtonDisable={this.state.formSubmitButtonDisable}
                    formError={this.state.formError}
                    formSubmitHandler={this.formSubmitHandler}
                    onDateChange={this.onDateChange}
                    handleChange={this.handleChange}
                    onNextButtonClick={this.onNextButtonClick}
                    getSocialImage={this.getSocialImage}
                    onFileChange={this.onFileChange}
                    image={this.state.image}
                    imageName={this.state.imageName}
                    imgURL={this.state.imageUrl}
                    profileImagePreviewUrl={this.state.profileImagePreviewUrl}
                    editMode={this.state.isEditMode}
                />
                {this.state.isLoading && <Loader />}
                <ToastContainer />
            </>
        )
    }
}

export default InstitutionalRegistration;
