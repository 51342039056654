import { CRYPTO_SECRET_KEY } from '../constants/applicationConstants';
var CryptoJS = require("crypto-js");

export const encrypt = value => {
  const encrypted = CryptoJS.AES.encrypt(value, CRYPTO_SECRET_KEY);
  return encrypted.toString();
};

export const decrypt = value => {
  const decrypted = CryptoJS.AES.decrypt(value, CRYPTO_SECRET_KEY);
  const originalText = decrypted.toString(CryptoJS.enc.Utf8);
  return originalText;
};


export const isValidEmail = (email) => {
  const pattern =
    /^(([^<>().,;:\s@"]+(\.[^<>().,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(String(email));
};

export const isValidPhone = (mobile) => {
  let pattern = /^[1-9]{1}[0-9]{9}$/;
  return pattern.test(mobile);
};

export function formatAmount(amount = 0) {
  return "$" + Number.parseFloat(amount).toFixed(2);
}

export const convertFloatTwoDecimal = function (num) {
  if (!!num && num > 0) return parseFloat(num).toFixed(2);
  else return "0.00";
};


export const emailRegex =
  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})$/;

export const postalCodeRegex = /^[A-Za-z]\d[A-Za-z]\s?\d[A-Za-z]\d$/;

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#()^@$!%*?&])[A-Za-z\d@$!#()^%*?&]{8,30}$/;

export const phoneRegex = /^[0-9]{10}$/;

export const websiteRegex = /(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

export const nameRegex = /^[A-Za-z\s]+$/;
export const cardRegex = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
export const monthRegex = /(0[1-9]|1[012])/;
export const yearRegex = /(?:(?:19|20)[0-9]{2})/;
export const cvcRegex = /^[0-9]{3}$/;


export const alphaNumericRegex = /^[A-Za-z0-9\s]+$/;
export const allRegex = /[^\n]+/;

export const alphabetWithSpaceRegex = /^[A-Za-z \s]+$/;

export const getCategoryName = {
  INV: "Investment",
  BNK: "Banking",
  INS: "Insurance",
  MTG: "Mortgage",
  RES: "Real Estate",
};

export const getMonthName = {
  "01": "Jan",
  "02": "Feb",
  "03": "Mar",
  "04": "Apr",
  "05": "May",
  "06": "Jun",
  "07": "Jul",
  "08": "Aug",
  "09": "Sept",
  "10": "Oct",
  "11": "Nov",
  "12": "Dec",
};

export const QueryByExpert = {
  PendingQuestion: "Pending Questions",
  AnsweredQuestion: "Answered Question",
};

export const AskTheExpert = {
  QuestionType: "ffQueryType",
  QueryType: "sfQueryType",
  Category: {
    Banking: "Banking",
    Insurance: "Insurance",
    Investment: "Investment",
    Mortgage: "Mortgage",
    RealEstate: "Real Estate",
  },
  AskQuery: "askQuery",
  none: "none",
  input: "input",
  text: "text",
  postalCode: "postalCode",
  province: "province",
};

export const Welcome = {
  Pending: "Pending",
  Answered: "Answered",
  expert: "EXPERT",
  welcome: "welcome",
  mktrep: "marketingDashboard",
  supp: "supportDashboard",
  admin: "adminDashboard",
  marketRep: "MKTREP",
  open: "open",
  suppCaps: "SUPP",
  adminCaps: 'ADMIN'
};

export const Payment = {
  premiumPlanName: "Premium",
  licenseAdd: "Add",
  annual: "ANNUAL",
  monthly: "MONTHLY",
  premium: "PREMIUM",
  basic: "Basic",
  basicPlanName: "BASIC",
  premiumAnnual: "PREMIUMMonthly",
  premiumMonthly: "PREMIUMAnnual",
  Monthly: "Monthly",
  Yearly: "Yearly",
  active: "active",
  verified: "VERIFIED",
};

export const ProfileScreen = {
  profileScreen: "profile screen",
  true: "True",
  expert: "EXPERT",
  cancelled: "CANCELLED",
  inactive: "INACTIVE",
  newPassword: "newpassword",
  notMatch: "notMatch",
};

export const AdminPortal = {
  PlanTypes: 'planTypes',
  PlanPrice: 'planPrice'
}

export const QuerySetup = {
  PreDefinedQuery: 'preDefinedQuery',
  QueryStatus: 'queryStatus'
}

export const MainSetup = {
  Category: 'category',
  SubCategory: 'subCategory',
  SubscriptionStatus: 'subscriptionStatus',
  UserStatus: 'userStatus',
  UserType: 'userType'
}

export const AddUser = {
  MktRep: 'MKTREP',
  Admin: 'ADMIN',
  Supp: 'SUPP'
}

export const formatInputValue = (date) => {
  if (!date) return '';
  return `${date.day}/${date.month}/${date.year}`;
};

export const getProperDateMonth = (value) => {

  if (value < 10 && value.length !== 2) {
    return `0${value}`
  } else {
    return value
  }
}

export const getRoute = (userType, screen) => {
  switch (screen) {
    case 'login':
      if (userType === 'institutional')
        return '/institutionalRegistration'
      if (userType === 'expert')
        return '/expertRegistration'
      if (userType === 'customer')
        return '/customerRegistration'
    case 'otpGeneration': return '/passwordAuth'

    case 'institutionalRegistration':
      if (userType === 'institutional')
        return '/passwordAuth'
      else if (userType === 'unVerified')
        return '/admin/manageUnverifiedInstitute'
      else
        return '/plans'
    case 'customerRegistration':
      if (userType === 'customer')
        return '/passwordAuth'
      else if (userType === 'unVerified')
        return '/admin/manageUnverifiedInstitute'
      else
        return '/plans'

    case 'description':
      if (userType === 'admin')
        return '/expertPlans'
      else if (userType === 'unVerified')
        return '/admin/manageUnverifiedExpert'
      else
        return '/passwordAuth'

    case 'expertRegistration': return '/specialInformations'

    case 'passwordAuth': return '/newPassword'
    case 'passwordAuth1': return '/specialInformations'


    case 'newPassword':
      if (userType === 'institutional')
        return '/plans'
      if (userType === 'expert')
        return '/expertPlans'
      else
        return '/passwordSuccess'

    case 'plans': return '/plansSummary'

    case 'plansSummary': return '/addCard'

    case 'onboarding-pwd':
      if (userType === 'Institutional')
        return '/Inst-plan-Dashboard'
      else
        return '/expert-plan-Dashboard'

    case 'onbording-pay':
      if (userType === 'Institutional')
        return '/Inst-success-Dashboard'
      else
        return '/expert-success-Dashboard'

    default: return '/'
  }
}

export const getProperWebsite = (website) =>{
  if(!website?.includes('http'))
    return `https://${website}`
  else return website
}

export const formatCanadianPhoneNumber = (number)=> {
  // Remove any non-numeric characters
  const cleanedNumber = String(number).replace(/\D/g, '');

  // Check if the number is valid length
  // if (cleanedNumber.length !== 10) {
  //   throw new Error('Invalid phone number length. Canadian phone numbers must be 10 digits.');
  // }

  // Divide the number into area code, exchange, and subscriber parts
  const areaCode = cleanedNumber.substring(0, 3);
  const exchange = cleanedNumber.substring(3, 6);
  const subscriber = cleanedNumber.substring(6);

  // Format the number
  return `(${areaCode}) ${exchange}-${subscriber}`;
}