import React, { Component } from "react";
import axios from "axios";
import {
  emailRegex,
  encrypt,
} from '../../shared/helper.js'
import { Loader } from "../common/loader/loader.jsx";
import {
  baseURL,
} from "../../constants/applicationConstants.js";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginPage from './login.jsx'

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: "",
        password: "",
      },
      isProcessing: false,
      formError: {},
      formSubmitButtonDisable: true,
      isLoading: false,
      isFirstTime: true
    };
  }

  componentDidMount = () => {
    
    sessionStorage.removeItem("instituteId")
    sessionStorage.removeItem("email")

    sessionStorage.removeItem("token")
    sessionStorage.removeItem("instLoginType")
    localStorage.removeItem("flowName")
    localStorage.removeItem("flowType")

  }

  onInputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value) {
      if (!this.inputRegexValidation(name, value)) {
        this.setState(
          {
            formData: {
              ...this.state.formData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "regx",
            },
          },
          () => this.isFromSubmissionBlock()
        );
      } else {
        this.setState(
          {
            formData: {
              ...this.state.formData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "",
            },
          },
          () => this.isFromSubmissionBlock()
        );
      }
    } else {
      this.setState(
        {
          formData: {
            ...this.state.formData,
            [name]: value,
          },
          formError: {
            ...this.state.formError,
            [name]: "",
          },
        },
        () => this.isFromSubmissionBlock()
      );
    }
  };
  // encrypt = () => {
  //   let ciphertext = CryptoJS.AES.encrypt('$C$pYuM#QM0f', 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3').toString();

  // }
  // decrypt = () => {
  //   let bytes = CryptoJS.AES.decrypt("U2FsdGVkX1+axK93BX1ZQ+AR8bXDqjtELPE7D6aJXwI=", 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3325');
  //   let originalText = bytes.toString(CryptoJS.enc.Utf8);
  // }


  onTouchHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value === "") {
      this.setState({
        formError: {
          ...this.state.formError,
          [name]: "req",
        },
      });
    } else {
      if (!this.inputRegexValidation(name, value)) {
        this.setState({
          formError: {
            ...this.state.formError,
            [name]: "regx",
          },
        });
      }
    }
  };

  inputRegexValidation = (name, value) => {

    if (name === "email") {
      return emailRegex.test(value);
    }
    else {
      //return passwordRegex.test(value)
      return true;
    }
  };

  isFromSubmissionBlock = () => {
    let { email, password } = this.state.formData;
    if (email && password) {
      if (
        !this.inputRegexValidation("email", email)
        ||
        !this.inputRegexValidation("password", password)
      ) {
        this.setState({
          formSubmitButtonDisable: true,
        });
      } else {
        this.setState({
          formSubmitButtonDisable: false,
        });
      }
    } else {
      this.setState({
        formSubmitButtonDisable: true,
      });
    }
  };
  formSubmitHandler = (e) => {
    this.setState({
      isLoading: true
    })
    e.preventDefault();
    let data = {
      email: this.state.formData.email,
      password: encrypt(this.state.formData.password)
      // password: this.state.formData.password

    };
    axios.post(`${baseURL}/customer/login`, data).then((res) => {
      sessionStorage.setItem("resendOtpData", JSON.stringify(data))

      sessionStorage.setItem("otpValidateToken", JSON.stringify(res.data?.data?.tokenValidate));

      // sessionStorage.setItem("auth", JSON.stringify(res?.data?.data));
      // sessionStorage.setItem("token", JSON.stringify(res.data?.data?.accessToken));
      // sessionStorage.setItem("masterID", JSON.stringify(res.data?.data?.customerId));
      // sessionStorage.setItem("customerId", JSON.stringify(res.data?.data?.customerId));
      // sessionStorage.setItem("loginData", JSON.stringify(res.data?.data));
      localStorage.setItem('flowName', 'customer')
      localStorage.setItem('flowType', 'customer')
      // sessionStorage.setItem("loginAs",'customer')

      this.setState({
        isLoading: false
      })
      // swal({
      //   title: "success",
      //   text: "You have logged-in successfully",
      //   icon: "success",
      //   successMode: true,
      // }).then(res => {
      //     if (res) {
      //       this.props.history.push("/dashboard");
      //     }
      // });
      toast.success("Login OTP sent to you Email successfully");
      setTimeout(() => {
        this.props.history.push("/otpLogin");
      }, 2000)

    }).catch((res) => {
      toast.error(res?.response.data.error.message);
      this.setState({
        isLoading: false
      })
    })
  };

  onResendOTPClick = () => {

    let dataCustomer = {
      category: "customer"
    }
    let email = JSON.parse(sessionStorage.getItem("email"))
    this.setState({ isLoading: true });
    axios.post(`${baseURL}/forgotPassword/${email}/sendOTP`, dataCustomer).then((res) => {
      sessionStorage.setItem("token", JSON.stringify(res.data?.data?.tokenValidate));
    }).catch((res) => {
      toast.error(res?.response.data.error.message);

      this.setState({ isLoading: false });
    })
  }

  onFirstLoginClick = () => {
    this.setState({
      isLoading: true
    })
    axios.post(`${baseURL}/customer/${this.state.formData?.email}/existence`).then(res => {
      this.setState({
        isLoading: false
      })
      // res && sessionStorage.setItem("token", JSON.stringify(res.data?.data?.tokenValidate));


      this.setState({ isFirstTime: false });
      if (res.data.data.onboardingStep === 'basic-info' || res.data.data.onboardingStep === 'verify-otp') {
        sessionStorage.setItem("email", JSON.stringify(this.state.formData?.email));
        localStorage.setItem('flowName', 'customer')
        localStorage.setItem('flowType', 'customer')
        this.onResendOTPClick()
        this.props.history.push('./passwordAuth')
      }
    }).catch(err => {
      toast.error(err.response.data.error.message);
      this.setState({
        isLoading: false
      })
    })
  }

  render() {
    return (
      <div>
        <LoginPage
          formData={this.state.formData}
          onInputChangeHandler={this.onInputChangeHandler}
          onTouchHandler={this.onTouchHandler}
          formSubmitButtonDisable={this.state.formSubmitButtonDisable}
          formError={this.state.formError}
          formSubmitHandler={this.formSubmitHandler}
          isLoading={this.state.isLoading}
          isFirstTime={this.state.isFirstTime}
          onFirstLoginClick={this.onFirstLoginClick}
        // encrypt={this.encrypt}
        // decrypt={this.decrypt}
        // isProcessing={this.state.isProcessing}
        />
        {this.state.isLoading && <Loader />}
      </div>
    );
  }
}

export default Login;
