import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import { baseURL } from '../../../../constants/applicationConstants.js';
import { allRegex, emailRegex, nameRegex, postalCodeRegex } from '../../../../shared/helper.js';
import { Loader } from '../../../common/loader/loader.jsx';
import CustomerAskTheExpertJsx from './adminAskTheExpert.jsx'

export default class AskTheExpert extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryData: null,
            subCategoryData: null,
            formData: {
                name: '',
                category: '',
                subCategory: '',
                stripeCode: '',
                planType: '',
                price: '',
                adsLimit: '',
                offerLimit: '',
                productLimit: '',
                expertLimit: '',
                secondaryStripeCode: '',
                planFor: '',
                secondaryPrice: ''
            },
            formError: {},
            formSubmitButtonDisable: true,
            isLoading: false,
            expertData: [],
            meta: {},
            page: 1,
        }
    }

    componentDidMount = () => {
        this.setState({ isLoading: true })
        let token = JSON.parse(sessionStorage.getItem('token'));
        axios.get(`${baseURL}/institutionCategoriesAndSubCategories`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            this.setState({ categoryData: res.data.data, isLoading: false })
        })
    }

    onCatgoryClick = (value) => {
        const subData = this.state.categoryData.filter(itm => itm.institutionCategoryId == value)
        this.setState({ subCategoryData: subData[0]?.subInstitutionCategories })
    }

    onInputChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (name === 'category') {
            this.onCatgoryClick(value)
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        'subCategory': '',
                    }
                }
            )

        }


        if (value) {
            if (!this.inputRegexValidation(name, value)) {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "regx",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            } else {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            }
        } else {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [name]: value,
                    },
                    formError: {
                        ...this.state.formError,
                        [name]: "",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }
    }

    inputRegexValidation = (name, value) => {
        if (name === 'name')
            return allRegex.test(value);
        if (name === 'postalCode')
            return postalCodeRegex.test(value);

        return true;
    };

    isFromSubmissionBlock = () => {
        let { name, category, subCategory } = this.state.formData;

        if (name && category ) {
            if (
                this.inputRegexValidation('name', name)
            ) {
                this.setState({
                    formSubmitButtonDisable: false
                });
            }
            else {
                this.setState({
                    formSubmitButtonDisable: true
                });
            }
        } else {
            this.setState({
                formSubmitButtonDisable: true
            });
        }
    };

    onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (name === 'category') {
            this.onCatgoryClick(value)
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        'subCategory': '',
                    }
                }
            )

        }

        if (value === "") {
            this.setState({
                formError: {
                    ...this.state.formError,
                    [name]: "req",
                },
            });
        } else {
            if (!this.inputRegexValidation(name, value)) {
                this.setState({
                    formError: {
                        ...this.state.formError,
                        [name]: "regx",
                    },
                });
            }
        }
    };
    onPaginationButtonClick = (page) => {
        window.scrollTo(0, 0);
        this.setState({ page: page }, () => this.onSubmitButtonClick())
    }

    onSubmitButtonClick = () => {
        let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
        this.setState({ isLoading: true });
        const { name,
            category,
            subCategory,
            stripeCode,
            planType,
            price,
            adsLimit,
            offerLimit,
            productLimit,
            expertLimit,
            secondaryStripeCode,
            planFor,
            secondaryPrice } = this.state.formData;

        const payload = {
            "planName": name,
            "planFor": planFor,
            "institutionCategoryId": category,
            "stripeCode": stripeCode,
            "planType": planType,
            "duration": planType == "monthly" ? 30 : 365,
            "price": price,
            "adsLimit": adsLimit,
            "offersLimit": offerLimit,
            "productsLimit": productLimit,
            "expertsLimit": expertLimit,
            "secondaryStripeCode": secondaryStripeCode,
            "secondaryPrice": secondaryPrice
        }
        axios.post(`${baseURL}/subscriptionPlan`, payload, {
            headers: { 'x-access-token': token }
        }).then(res => {
            this.setState({ isLoading: false })
            toast.success("Plans added succuessfully!")
            setTimeout(() => {
                window.location.reload()

            }, 2000);
        }).catch(err => {
            this.setState({ isLoading: false })
            toast.error(err?.response.data.error.message);
        })
    }

    onViewProfieClick = (url) => {
        this.props.history.push(`/expertPpv/${url}`)
    }

    render() {
        return (
            <div>
                <CustomerAskTheExpertJsx
                    categoryData={this.state.categoryData}
                    subCategoryData={this.state.subCategoryData}
                    formData={this.state.formData}
                    onInputChange={this.onInputChange}
                    formError={this.state.formError}
                    onTouchHandler={this.onTouchHandler}
                    formSubmitButtonDisable={this.state.formSubmitButtonDisable}
                    onSubmitButtonClick={this.onSubmitButtonClick}
                    expertData={this.state.expertData}
                    meta={this.state.meta}
                    onViewProfieClick={this.onViewProfieClick}
                    onPaginationButtonClick={this.onPaginationButtonClick}
                />
                {this.state.isLoading && <Loader />}
                <ToastContainer />            </div>
        )
    }
}
