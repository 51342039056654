import React from 'react'
import { NEW_URL } from '../../../../constants/applicationConstants';
import dropbox from "../../../../assets/images/dropbox.svg";
import { Pagination } from '../../../common/pagination/pagination';
import moment from 'moment';

const CustomerQnaAnswerJsx = (props) => {
  const {
    data,onPaginationButtonClick,meta
  } = props;

  return (
    <>
      <section class="alldashboard">

        <div className="dashboardrightpanel martop40 mb-5">
          <div className="questioninner mt-4">
            {
              
              data.length ? data?.map(list => {
                return <div className="questioninnerin mb-3">
                  <h5 className="h5_style"><span>Question:</span> <b>{list.askExpertQuestionUserResponse.question}</b></h5>
                  
                  
                  <h5 className="h5_style"><span>Answer :</span></h5>
                  <p>{list.answer}</p>

                  <div className="d-flex questioninnerinbottom">                    
                    <div className="expertquestiondetails">
                      <div className="expertuser"><img src={!list?.expert?.imageUrl ? `https://ui-avatars.com/api/?name=${list?.expert?.firstName}+${list?.expert?.lastName}&rounded=true` : `${NEW_URL}/${list?.expert?.imageUrl}`} alt={''} /></div>
                      <div className="expName"><span>{`${list?.expert?.firstName} ${list?.expert?.lastName}`}</span>
                      <div className="questiondate">{moment(list?.answeredAt).format('MM-DD-YYYY hh:mm A')}</div></div>
                    </div>
                    <div className="expertquestiondetailscategory"><span>{list.askExpertQuestionUserResponse.parentInstitutionCategory}</span></div>
                  </div> 
                </div>
              }) :

                <div className="dropdefault text-center">
                  <div className="dropdefaultimage">
                    <img src={dropbox} alt={''} />
                  </div>
                  <div className="dropdefaulttext">
                    <h5>You don't have any answered questions yet. </h5>
                  </div>
                </div>
            }
            {data.length > 0 && <Pagination
                      limit={10}
                      totalCount={meta?.totalCount}
                      onPaginationButtonClick={onPaginationButtonClick}
                    />}
          </div>
        </div>

      </section>

    </>

  );
};

export default CustomerQnaAnswerJsx;