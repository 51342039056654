import React from 'react';
import Sidebar from "../../sidebar/sidebar";
import { Footer } from '../../../../common/footer/footer';
import { Link } from "react-router-dom";
export const FinalAsktheExpertScreenJSX = (props) => {

    const { onHomebuttonClick } = props;
    return (
        <>
            <section class="entiredashboard">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-3">


                            <div className="dashboardleftpanel martop40">

                                <div className="dashboardlinkpop martop40">
                                    <Sidebar />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-sm-9">
                            <div style={{ marginBottom: '50px' }}>
                                
                                    <div className="confy">
                                        <div className="confirmtion">
                                            <h2 className="text-center h2_style">Thank you for your question!</h2>
                                            <h5 className="text-center h5_style fst-italic">Conscious finance boosts overall well-being. </h5>
                                            <h5 className="text-center h5_style fst-italic">Your question has been assigned to the experts. </h5>
                                            <h5 className="text-center h5_style fst-italic">Expect tailored & personalized solutions shortly!</h5>
                                            <div className="confirmbutton mt-4 d-flex"><Link to={'/customerDashboard'} className="ctatwobutton">Home</Link>
                                            <Link to={'/customerAskTheExpert'}  className="ctaonebutton">Ask Another Query</Link></div>
                                        </div>
                                    </div>
             
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}