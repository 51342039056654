import React, { Component } from 'react';
import {
    emailRegex, alphaNumericRegex, websiteRegex, alphabetWithSpaceRegex, nameRegex,
    postalCodeRegex, getRoute, getProperDateMonth
} from '../../../shared/helper.js';
import CustomerRegistrationJSX from './customerRegistration.jsx';
import {
    baseURL, uploadURL
} from "../../../constants/applicationConstants.js";
import axios from 'axios'
import signUpjson from "./customerRegistration.json";
import facebook from "../../../assets/images/facebook.svg";
import twitter from "../../../assets/images/twitter.svg";
import insta from "../../../assets/images/insta.svg";
import linkedin from "../../../assets/images/linkedin.svg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class CustomerRegistration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                institutionName: "",
                legalInstitutionName: "",
                institutionEmail: "",
                segment: "",
                category: null,
                sizeOfInstitution: "",
                dateOfBirth: null,
                firstName: "",
                lastName: "",
                gender: "",
                email: "",
                altEmail: "",
                phone: "",
                postalCode: "",
                province: "",
                city: "",
                country: "",
                street: "",
                licenseNo: "",
                regulatory: "",
                primaryContact: "",
                secondaryContact: "",
                website: "",
                facebook: "",
                insta: "",
                twitter: "",
                linkedin: "",
                tempCat: null,
                occupation: "",
                annual_income: "",
                netWorth: "",
                annualHousehold: ""

            },
            isProcessing: false,
            formError: {},
            formSubmitButtonDisable: true,
            isLoading: false,
            fields: signUpjson,
            _URL: window.URL || window.webkitURL,
            imageName: null,
            imageUrl: "",
            fileInfo: null,
            profileImagePreviewUrl: null,
            isEditMode: false,
            image: "",
            sameError: false
        };

    }

    setDefaultValues = (data) => {
        const { name, legalName, gender, email, institutionSegmentId, instituteSizeId, primaryInstituteUser, postalCode,
            stateCode, city, countryCode, address, regulatoryAssociation, contactNumber, customerCareNumber, website,
            socialMedia, instituteLicenses, instituteCategories, incorporationDate, interests, imageUrl, alternativeEmail, dateOfBirth, phoneNumber, alternatePhoneNumber, occupation, annualIncome, annualHouseHoldIncome, netWorth } = data;

        const { firstName, lastName } = data;

        sessionStorage.setItem("emailCheck", email)
        sessionStorage.setItem("altCheck", alternativeEmail)
        const date = dateOfBirth && dateOfBirth?.split('-') || null;

        let tempArr = [];

  
        interests?.forEach(cat => {
              tempArr.push({ label: cat.institutionCategory.name, value: cat.institutionCategoryId });
           
        })

        this.setState({
            formData: {
                institutionName: name,
                occupation: occupation,
                annualHousehold: annualHouseHoldIncome,
                annual_income: annualIncome,
                netWorth: netWorth,
                legalInstitutionName: legalName,
                institutionEmail: email,
                email: email,
                segment: institutionSegmentId,
                tempCat: instituteCategories,
                sizeOfInstitution: instituteSizeId,
                dateOfBirth: date && {
                    day: date && parseInt(date[2]),
                    month: date && parseInt(date[1]),
                    year: date && parseInt(date[0])
                },
                gender: gender,
                firstName: firstName,
                lastName: lastName,
                altEmail: alternativeEmail,
                postalCode: postalCode,
                province: stateCode,
                city: city,
                country: countryCode,
                street: address,
                licenseNo: instituteLicenses?.map(x => x.licenseNumber)?.join(','),
                regulatory: regulatoryAssociation,
                primaryContact: phoneNumber,
                secondaryContact: alternatePhoneNumber,
                website: website,
                category:[...tempArr]

            },
            isLoading: false,
            isEditMode: true,
            imageUrl: imageUrl,
            image: imageUrl && imageUrl,

        }, () => {
            this.getSegments();
            this.isFromSubmissionBlock();
        })
    }

    componentDidMount = () => {
        this.setState({ isLoading: true })
        let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
        // if (!token) {
        //     this.props.history.push("/");
        //   }

        sessionStorage.removeItem("altCheck")
        sessionStorage.removeItem("emailCheck")
        let customerId = sessionStorage.getItem("customerId") && JSON.parse(sessionStorage.getItem("customerId"))

        let verifyInstituteID = localStorage.getItem('VerifyInstituteID');
        let data = {
            customerId: customerId
        }
        if (verifyInstituteID) {
            axios.get(`${baseURL}/institute/${verifyInstituteID}/details`, {
                headers: {
                    'x-access-token': token
                }
            }).then(res => {
                this.setDefaultValues(res.data.data)
            })
        }


        else if (token && customerId) {
            axios.get(`${baseURL}/customerbyid/${customerId}`, data).then(res => {
                this.setDefaultValues(res.data.data);
            })
        }
        else {
            this.getSegments();
        }

        axios.get(`${baseURL}/countries`).then(res => {
            this.getUpdateJSON('country', res.data.data);
        })

        axios.get(`${baseURL}/states`).then(res => {
            this.getUpdateJSON('province', res.data.data);
        })

        axios.get(`${baseURL}/institutionSizes`).then(res => {
            this.getUpdateJSON('sizeOfInstitution', res.data.data);
            this.setState({ isLoading: false })
        })
        this.getCategories()
    }

    getCategories = () => {
        this.setState({ isLoading: true })
        let token = JSON.parse(sessionStorage.getItem('token'));
        axios.get(`${baseURL}/institutionCategoriesAndSubCategories`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            this.setState({ category: res.data.data, isLoading: false })
            this.getUpdateJSON('category', res.data.data);

        })
    }

    getSegments = () => {
        axios.get(`${baseURL}/instituteSegments`).then(res => {
            this.getUpdateJSON('segment', res.data.data);
            let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
            let instituteId = sessionStorage.getItem("instituteId") && JSON.parse(sessionStorage.getItem("instituteId"))
            instituteId = localStorage.getItem('VerifyInstituteID') ?? instituteId

            if (token && instituteId) {
                const category = res.data.data.filter(item => item.institutionSegmentId === this.state.formData.segment)[0]?.institutionCategories;
                this.getUpdateJSON('category', category);

                let tempArr = [];

                category?.map(cat => {
                    this.state.formData.tempCat?.map(abc => {
                        if (cat.institutionCategoryId === abc.institutionCategoryId)
                            tempArr.push({ label: cat.name, value: cat.institutionCategoryId });
                    })
                })
                this.setState({ formData: { ...this.state.formData, category: [...tempArr] } }, () => this.isFromSubmissionBlock())
            }
        })
    }

    getUpdateJSON = (label, values) => {
        let dropdownOptions = [{ label: 'Select', value: null }];

        if (label === 'segment') {
            values.map(value => {
                dropdownOptions.push({ label: value.name, value: value.institutionSegmentId, category: value.institutionCategories })
            })
        }
        else if (label === 'category') {
            dropdownOptions = []
            values?.map(value => {
                dropdownOptions.push({ label: value.name, value: value.institutionCategoryId })
            })
        }
        else if (label === 'sizeOfInstitution') {
            values.map(value => {
                dropdownOptions.push({ label: value.size, value: value.institutionSizeId })
            })
        }
        else if (label === 'country') {
            values.map(value => {
                dropdownOptions.push({ label: value.name, value: value.countryCode })
            })
        }
        else if (label === 'province') {
            values.map(value => {
                dropdownOptions.push({ label: value.name, value: value.stateCode })
            })
        }

        let tempArr = [...this.state.fields];
        tempArr.map((data, index) => {
            if (data.name === label)
                tempArr[index] = { ...tempArr[index], options: dropdownOptions };
            else {
                data.fields?.map((data1, index) => {
                    if (data1.name === label)
                        data.fields[index] = { ...data.fields[index], options: dropdownOptions };
                })
            }
        })
        this.setState({ fields: tempArr })
    }

    handleChange = (e, item) => {
        if (item.name === 'segment') {
            this.onSegmentChange(item, e.value)
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [item.name]: e.value,
                        category: []
                    },
                    formError: {
                        ...this.state.formError,
                        [item.name]: "regx",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }
        else if (item.name === 'category') {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [item.name]: e,
                    },
                    formError: {
                        ...this.state.formError,
                        [item.name]: "regx",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }
        else {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [item.name]: e.value
                    },
                    formError: {
                        ...this.state.formError,
                        [item.name]: "regx",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }
    }

    onSegmentChange = (items, value) => {
        const category1 = items?.options?.filter(item => item.value == value)[0].category;
        this.getUpdateJSON('category', category1);
    }

    onInputChangeHandler = (e, item = '') => {
        let name = e.target.name;
        let value = e.target.value;
        if (value) {
            if (name === "email" || name === "altEmail") {
                if (name === "email") {
                    sessionStorage.setItem("emailCheck", value)
                }
                if (name === "altEmail") {
                    sessionStorage.setItem("altCheck", value)
                }
                let emailCheck = sessionStorage.getItem("emailCheck")
                let altCheck = sessionStorage.getItem("altCheck")
                if (emailCheck === altCheck) {
                    this.setState({ sameError: true })
                } else {
                    this.setState({ sameError: false })

                }
            }
            if (!this.inputRegexValidation(name, value)) {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "regx",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );

            } else {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );

            }
        } else {
            if (name === "email" || name === "altEmail") {
                if (name === "email") {
                    sessionStorage.setItem("emailCheck", value)
                }
                if (name === "altEmail") {
                    sessionStorage.setItem("altCheck", value)
                }
                let emailCheck = sessionStorage.getItem("emailCheck")
                let altCheck = sessionStorage.getItem("altCheck")
                if (emailCheck === altCheck) {
                    this.setState({ sameError: true })
                } else {
                    this.setState({ sameError: false })

                }
            }
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [name]: value,
                    },
                    formError: {
                        ...this.state.formError,
                        [name]: "",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }
    };


    onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value === "") {
            this.setState({
                formError: {
                    ...this.state.formError,
                    [name]: "req",
                },
            });
        } else {
            if (!this.inputRegexValidation(name, value)) {
                this.setState({
                    formError: {
                        ...this.state.formError,
                        [name]: "regx",
                    },
                });
            }
        }
    };

    getProperPhoneNumber = (value) => {

        if (!isNaN(value)) {
            return value
        }

        const number = value.split(' ');
        return number[0].replace('(', '').replace(')', '') + number[1] + number[2]
    }

    inputRegexValidation = (name, value) => {
        if (name === 'postalCode')
            return postalCodeRegex.test(value);
        if (name === 'licenseNo')
            return alphaNumericRegex.test(value);
        else if (name === 'legalInstitutionName' || name === 'regulatory' || name === 'city')
            return alphabetWithSpaceRegex.test(value);
        else if (name === 'firstName' || name === 'lastName' || name === 'city')
            return nameRegex.test(value);
        else if (name === "email" || name === "altEmail")
            return emailRegex.test(value);
        else if (name === "phone" || name === "primaryContact" || name === "secondaryContact")
            return !this.getProperPhoneNumber(value)?.includes('_');
        else if (name === "website")
            return websiteRegex.test(value);
        else if (name === 'facebook')
            return value.includes('facebook')
        else if (name === 'insta')
            return value.includes('instagram')
        else if (name === 'twitter')
            return value.includes('twitter')
        else if (name === 'linkedin')
            return value.includes('linkedin')

        return true;
    };

    isFromSubmissionBlock = () => {
        let {
            altEmail, firstName, lastName, email, postalCode, street, province, city, country, primaryContact, secondaryContact } = this.state.formData;

        if (
            firstName && lastName && email && postalCode &&
            province && city && country) {

            if (
                this.inputRegexValidation("firstName", firstName) &&
                this.inputRegexValidation("lastName", lastName) &&
                // this.inputRegexValidation("altEmail", altEmail) &&
                this.inputRegexValidation("email", email) &&
                this.inputRegexValidation("primaryContact", primaryContact) &&
                this.inputRegexValidation("postalCode", postalCode) &&
                // this.inputRegexValidation("street", street) &&
                this.inputRegexValidation("province", province) &&
                this.inputRegexValidation("country", country) &&
                this.inputRegexValidation("city", city) &&

                // non-required fields

                (altEmail ? this.inputRegexValidation("altEmail", altEmail) : true) &&
                (secondaryContact ? this.inputRegexValidation("secondaryContact", secondaryContact) : true) &&
                (primaryContact ? this.inputRegexValidation("primaryContact", primaryContact) : true)

            ) {
                this.setState({
                    formSubmitButtonDisable: false,
                });
            } else {
                this.setState({
                    formSubmitButtonDisable: true,
                });

            }
        } else {
            this.setState({
                formSubmitButtonDisable: true,
            });

        }
    };

    onDateChange = (date) => {
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    dateOfBirth: date,
                }
            },
            () => this.isFromSubmissionBlock()
        );
    }

    getSocialImage = (item) =>
        item.name === 'facebook' ? facebook : item.name === 'insta' ? insta : item.name === 'twitter' ? twitter : linkedin

    onNextButtonClick = () => {
        this.setState({
            isLoading: true
        })
        let formData1 = new FormData();

        if (this.state.fileInfo) {
            formData1.append('image', this.state.fileInfo)
            formData1.append('imageCategory', "customer")

            axios.post(`${uploadURL}/upload-file`, formData1).then(resp => {
                if (resp.status === 200) {
                    this.setState({ image: resp.data.filename })
                    setTimeout(() => {
                        this.onNextButtonClick1()
                    }, 2000)
                }
            }).catch(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
        else this.onNextButtonClick1();
    };


    onNextButtonClick1 = () => {
        sessionStorage.removeItem("altCheck")
        sessionStorage.removeItem("emailCheck")
        this.setState({
            isLoading: true
        })

        let image = this.state.image;

        const { gender, dateOfBirth, category, firstName, lastName, city, postalCode, street, province, country, email,
            altEmail, primaryContact, secondaryContact, occupation, annual_income, annualHousehold, netWorth } = this.state.formData;

        const status = localStorage.getItem('verificationStatus');

        const payloadData1 = {
            "firstName": firstName,
            "lastName": lastName,
            "email": email,
            "alternativeEmail": altEmail,
            "phoneNumber": this.getProperPhoneNumber(primaryContact) || null,
            "alternatePhoneNumber": secondaryContact,
            "dateOfBirth": dateOfBirth && `${dateOfBirth?.year}-${getProperDateMonth(dateOfBirth?.month)}-${getProperDateMonth(dateOfBirth.day)}` || null,
            "gender": gender,
            "address": street || null,
            "city": city,
            "stateCode": province,
            "countryCode": country,
            "postalCode": postalCode.toString(),
            "occupation": occupation,
            "annualIncome": annual_income,
            "interests": category?.map(cat => cat.value),
            "netWorth": netWorth,
            "occupationCountry": country,
            "annualHouseHoldIncome": annualHousehold,
            // "instituteCategories": category?.map(cat => cat.value),

            imageUrl: image
        }
        let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
        let customerId = sessionStorage.getItem("customerId") && JSON.parse(sessionStorage.getItem("customerId"))

        const payloadData = image ? { ...payloadData1, imageUrl: image } : { ...payloadData1 }

        let verifyInstituteID = localStorage.getItem('VerifyInstituteID');

        if (verifyInstituteID) {
            axios.put(`${baseURL}/institute/${verifyInstituteID}/verifyAccount`, { ...payloadData, status: 'verified' }, {
                headers: {
                    'x-access-token': token
                }
            }).then(res => {
                if (res.data.success) {
                    toast.success('Account Verified');
                    setTimeout(() => {
                        this.props.history.push('./admin/manageUnverifiedInstitute')
                    }, 1000)
                    window.scrollTo(0, 0);
                    this.setState({
                        isLoading: false
                    })
                }
                else {
                    toast.error(res.data?.error.message)
                    this.setState({
                        isLoading: false
                    })
                }

            }).catch(err => {
                toast.error(err?.response.data?.error.message)
                this.setState({
                    isLoading: false
                })
            })
        } else if (customerId) {
            let flowType = localStorage.getItem('flowName') ?? ''
            axios.put(`${baseURL}/customer/${customerId}/onboarding`, {
                "tokenValidate": token, ...payloadData
            }).then(res => {
                if (res.data.success) {
                    sessionStorage.setItem("token", JSON.stringify(res.data?.data?.tokenValidate));
                    sessionStorage.setItem("email", JSON.stringify(this.state.formData.email));
                    sessionStorage.setItem("customerId", JSON.stringify(res.data?.data?.customerId));
                    if (!flowType) {
                        localStorage.setItem('flowName', 'customer')
                        localStorage.setItem('flowType', 'customer')
                        flowType = 'customer'
                    }
                    flowType = (status == 'unVerified' ? 'unVerified' : flowType)
                    // window.location.reload()
                    setTimeout(() => {
                        this.props.history.push(getRoute(flowType, 'customerRegistration'));

                        window.location.reload()
                    }, 2000);

                    window.scrollTo(0, 0);
                    this.setState({
                        isLoading: false
                    })
                }
                else {
                    toast.error(res.data.error.message)
                    this.setState({
                        isLoading: false
                    })
                }

            }).catch(err => {
                toast.error(err.response.data.error.message)
                this.setState({
                    isLoading: false
                })
            })
        }
        else {
            let flowType = localStorage.getItem('flowName') ?? ''
            axios.post(`${baseURL}/customer`, payloadData).then(res => {
                if (res.data.success) {
                    sessionStorage.setItem("token", JSON.stringify(res.data?.data?.tokenValidate));
                    sessionStorage.setItem("email", JSON.stringify(this.state.formData.email));
                    sessionStorage.setItem("customerId", JSON.stringify(res.data?.data?.customerId));
                    if (!flowType) {
                        localStorage.setItem('flowName', 'customer')
                        localStorage.setItem('flowType', 'customer')
                        flowType = 'customer'
                    }
                    flowType = (status == 'unVerified' ? 'unVerified' : flowType)
                    this.props.history.push(getRoute(flowType, 'customerRegistration'));
                    window.scrollTo(0, 0);
                    this.setState({
                        isLoading: false
                    })
                }
                else {
                    toast.error(res.data?.error.message)
                    this.setState({
                        isLoading: false
                    })
                }

            }).catch(err => {
                toast.error(err?.response.data?.error.message)
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    validFileName = (name) => name.includes('.jpeg') || name.includes('.jpg') || name.includes('.png');

    onFileChange = (e) => {
        if (e.target.files[0]?.size) {
            if (e.target.files[0]?.size < 512000) {
                if (e.target.files[0] && this.validFileName(e.target.files[0].name)) {
                    // if ((file = e.target.files[0])) {
                    //     img = new Image();
                    //     img.onload = function () {
                    //         if (this.height > 800) {
                    //             toast.error("big height")
                    //         }
                    //         else{
                    //             toast.error("small height")
                    //             img.src = this.state._URL.createObjectURL(file);
                    //             this.setState({ fileInfo: e.target.files[0] })
                    //             this.setState({ profileImagePreviewUrl: URL.createObjectURL(e.target.files[0]) })
                    //             this.setState({ imageName: e.target.files[0].name },()=> this.isFromSubmissionBlock())
                    //         }
                    //     };
                    //     img.onerror = function () {
                    //         toast.error("not a valid file: " + file.type);
                    //     };
                    // }
                    this.setState({ fileInfo: e.target.files[0] }, () => this.isFromSubmissionBlock())
                    this.setState({ profileImagePreviewUrl: URL.createObjectURL(e.target.files[0]) })
                    this.setState({ imageName: e.target.files[0].name })
                }
                else
                    toast.error('.jpeg/.jpg/.png format required')
            }
            else
                toast.error('File should be less than 500 kb.')
        }
    }

    render() {
        return (
            <>
                <CustomerRegistrationJSX
                    field={this.state.fields}
                    formData={this.state.formData}
                    onInputChangeHandler={this.onInputChangeHandler}
                    onTouchHandler={this.onTouchHandler}
                    formSubmitButtonDisable={this.state.formSubmitButtonDisable}
                    formError={this.state.formError}
                    formSubmitHandler={this.formSubmitHandler}
                    onDateChange={this.onDateChange}
                    handleChange={this.handleChange}
                    onNextButtonClick={this.onNextButtonClick}
                    getSocialImage={this.getSocialImage}
                    onFileChange={this.onFileChange}
                    image={this.state.image}
                    imageName={this.state.imageName}
                    imgURL={this.state.imageUrl}
                    profileImagePreviewUrl={this.state.profileImagePreviewUrl}
                    editMode={this.state.isEditMode}
                    sameError={this.state.sameError}
                />
                {/* {this.state.isLoading && <Loader />} */}
                <ToastContainer />
            </>
        )
    }
}

export default CustomerRegistration;
