import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { Loader } from '../../../common/loader/loader.jsx';
import CustomerBlogJsx from './customerBlogs.jsx'
import {BLOG_URL,BLOG_URL_TOKEN} from "../../../../constants/applicationConstants"

export default class CustomerBlog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blogData: null,
            isProcessing: false,
            page: 1,
            blogType: 'real-estate'
        }
    }

    getBlogData = () => {
        var data = new FormData();
        data.append('limit', '10');
        data.append('device_token', '20.204.66.244');
        data.append('paged', this.state.page)
        data.append('cat_slug', this.state.blogType.toLowerCase())
        this.setState({ isProcessing: true })

        axios.post(`${BLOG_URL}`, data,
            {
                headers: { 'Eba-access-api-base-token': BLOG_URL_TOKEN }
            }).then(res => {
                this.setState({ blogData: res.data, isProcessing: false });
            }).catch(err => {
                this.setState({ isProcessing: false })
            })
    }

    componentDidMount = () => {
        // let token = JSON.parse(sessionStorage.getItem('token'));
        // if (!token){
        //   this.props.history.push("/");   
        //  }
        this.getBlogData();
    }

    onPaginationButtonClick = (page) => {
        window.scrollTo(0, 0);
        this.setState({ page: page }, () => this.getBlogData())
    }

    onBlogTypeClick = (title) => {
        this.setState({ blogType: title }, () => this.getBlogData());
    }

    render() {
        return (
            <div>
                <CustomerBlogJsx
                    blogData={this.state.blogData}
                    onPaginationButtonClick={this.onPaginationButtonClick}
                    blogType={this.state.blogType}
                    onBlogTypeClick={this.onBlogTypeClick}
                />
                {this.state.isProcessing && <Loader />}
            </div>
        )
    }
}