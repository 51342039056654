import React from 'react'
import { useEffect, useState } from 'react';
import call from "../../../../assets/images/call.svg";
import { Pagination } from '../../../common/pagination/pagination';
import moment from 'moment';

import map from "../../../../assets/images/map.svg";
import envelop from "../../../../assets/images/envelop.svg";
import InputMask from 'react-input-mask';
import { Footer } from '../../../common/footer/footer';
import { uploadURL, NEW_URL } from '../../../../constants/applicationConstants.js';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { formatCanadianPhoneNumber } from '../../../../shared/helper'
import { baseURL } from '../../../../constants/applicationConstants.js';
import axios from 'axios';
import Sidebar from "../sidebar/sidebar.js";

export const CustomerAskTheExpertJsx = (props) => {
  const { t } = useTranslation();
  const {
    categoryData,
    subCategoryData,
    formData,
    onInputChange,
    formError,
    onTouchHandler,
    formSubmitButtonDisable,
    onSubmitButtonClick,
    onPaginationButtonClick,
    expertData,
    onViewProfieClick,
    deleteOffer, editCategory
    // meta
  } = props;
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])

  const [posts, setPosts] = useState([]);
  const [meta, setMeta] = useState({});
  const [totalPosts, setTotalPosts] = useState(0);
  const [fetch, setFetch] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(0);
  const [page, setPage] = useState(1);



  const handleOnLoadMore = () => {
    setPage(page + 1);

    // fetchPosts();

  };



  // {t("PRINT.TITLE")}
  return (
    <>
      <section className="entiredashboard admindashboard">
        <div className="container">

          <div className="row">
            <div className="col-lg-3 col-sm-3">


              <div className="dashboardleftpanel martop40">

                <div className="dashboardlink martop40">
                  <Sidebar />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-9">
              <div className="dashboardrightpanel martop40">
                <h2 className="h2_style">Customer Query</h2>


                
              </div>

              <div className="expertsection martop30 mb-5">
                <div className="searchdetails searchdetalsen">
                  <table className="searchdetailsdb">
                    <tr>
                      <th>Customer ID</th>
                      <th>Name</th>
                      <th>Email</th>

                      <th>Phone</th>
                      <th>Address</th>
                     



                      <th>Query</th>
                      <th>Created ad</th>
                      {/* <th>Action</th> */}
                    </tr>

                    {expertData.map((item, i) => (
                      <>
                        <tr>
                          <td>{item.customerId}</td>
                          <td>{item.customerDetails.firstName} {item.customerDetails.lastName}</td>
                          
                          <td>{item.customerDetails.email}</td>
                          <td>{item.customerDetails.phoneNumber}</td>
                          <td>{item.customerDetails.address}, {item.customerDetails.city}, {item.customerDetails.postalCode}</td>
                        


                          <td>{item.query}</td>
                          <td>{moment(item.updatedAt).format('MM-DD-YYYY')}</td>
                          {/* <td>
                          <button className="btn btn-success" onClick={() => editCategory(item?.institutionCategoryId)}>Edit</button>
                                  <button className="btn btn-danger"  onClick={() => deleteOffer(item?.institutionCategoryId)} >delete</button></td> */}
                          
                        </tr>
                      </>
                    ))}

                  </table>
                </div>
              </div>


            </div>

          </div>

        </div>
      </section>

      <Footer />
    </>

  );
};

export default CustomerAskTheExpertJsx;