import React from 'react'
import moment from 'moment';
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import userimage from "../../../../assets/images/userimage.svg";
import cross from "../../../../assets/images/cross.svg";
import { Footer } from '../../../common/footer/footer';
import Sidebar from "../sidebar/sidebar.js";
import dropbox from "../../../../assets/images/dropbox.svg";

const ExpertDashboardHomeJsx = (props) => {
  const {
    giveAnswer, questionData, sendAnswer, onInputChangeHandler, openModal, isOpenModal,givenAnswer
  } = props;


  return (
    <>
      <section class="entiredashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-3">


              <div className="dashboardleftpanel martop40">

                <div className="dashboardlinkpop martop40">
                  <Sidebar />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-9">
              <div className="dashboardrightpanel martop40 mb-5">
                <div className="dashboardrightpannerinner">
                  <div className="dashboardrightpannerinnerleft">
                    <h2 className="h2_style font-weight-600">Home</h2>
                    <h5 className="h5_style">Welcome to your personalized dashboard, where you can easily access a variety of features. </h5>
                    <h5 className="h5_style">These include answering questions, creating ads, reading blogs, and much more. </h5>
                    <h5 className="h5_style fst-italic fw-light">Enjoy the convenience of having everything you need at your fingertips.</h5>
                  </div>
                  <div className="dashboardrightpannerinnerright">
                    <div className="signout">
                      <a href="#logout">
                        <img src={Sign_out_squre} alt={''} /><span className="title">Logout</span>
                      </a>
                    </div>
                    <div id="logout" class="modal-window">
                      <div>
                        <a title="Close" class="modal-close">Close</a>
                        <div className="modal-header">

                        </div><div className="modal-body mb-4">
                          <div className="modal-body-inner">
                            <h2 className="h2_style text-center">Are you sure you want to
Logout ?</h2>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="ctaonebutton"> Save</button>
                          <button type="button" className="ctatwobutton"> Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="questioninner mt-4">
                  <h2>High Priority Questions</h2>
                  <h5 className="h5_style">By responding promptly and thoroughly to questions, you can establish trust and credibility with potential customers. Additionally, providing helpful and informative responses can position you as an authority in your industry.
Don’t miss your potential leads! </h5>
                  <h5 className="h5_style fst-italic fw-light mb-3">These questions will expire in less than 24 hours. Answer them now.</h5>

                  {/* <div className="questioninnerin mb-3">
                    <p>Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lor ipsum Lorem ipsum sit amet Lorepsum sit amet Lorem ipsu amet Lorem ipsum Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum et Lorem ipsum Lorem ipsum sit amet Lor........</p>
                    <div className="d-flex questioninnerinbottom">
                      <div className="expertquestiondetails">
                        <div className="expertuser"><img src={userimage} /></div>
                        <div className="questionid"><span>Question ID -</span> n5veg43<div className="questiondate">17- 12 -2022</div></div>

                      </div>
                      <div className="expertquestiondetailscategory"><span>Banking</span></div>
                      <button type="button" className="ctaonebutton">Answer Now</button>
                    </div>
                  </div>
                  <div className="questioninnerin mb-3">
                    <p>Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lor ipsum Lorem ipsum sit amet Lorepsum sit amet Lorem ipsu amet Lorem ipsum Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum et Lorem ipsum Lorem ipsum sit amet Lor........</p>
                    <div className="d-flex questioninnerinbottom">
                      <div className="expertquestiondetails">
                        <div className="expertuser"><img src={userimage} /></div>
                        <div className="questionid"><span>Question ID -</span> n5veg43<div className="questiondate">17- 12 -2022</div></div>

                      </div>
                      <div className="expertquestiondetailscategory"><span>Banking</span></div>
                      <button type="button" className="ctaonebutton">Answer Now</button>
                    </div>
                  </div>
                  <div className="questioninnerin mb-3">
                    <p>Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lor ipsum Lorem ipsum sit amet Lorepsum sit amet Lorem ipsu amet Lorem ipsum Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum et Lorem ipsum Lorem ipsum sit amet Lor........</p>
                    <div className="d-flex questioninnerinbottom">
                      <div className="expertquestiondetails">
                        <div className="expertuser"><img src={userimage} /></div>
                        <div className="questionid"><span>Question ID -</span> n5veg43<div className="questiondate">17- 12 -2022</div></div>

                      </div>
                      <div className="expertquestiondetailscategory"><span>Banking</span></div>
                      <button type="button" className="ctaonebutton">Answer Now</button>
                    </div>
                  </div>
                  <div className="questioninnerin mb-3">
                    <p>Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lor ipsum Lorem ipsum sit amet Lorepsum sit amet Lorem ipsu amet Lorem ipsum Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum et Lorem ipsum Lorem ipsum sit amet Lor........</p>
                    <div className="d-flex questioninnerinbottom">
                      <div className="expertquestiondetails">
                        <div className="expertuser"><img src={userimage} /></div>
                        <div className="questionid"><span>Question ID -</span> n5veg43<div className="questiondate">17- 12 -2022</div></div>

                      </div>
                      <div className="expertquestiondetailscategory"><span>Banking</span></div>
                      <button type="button" className="ctaonebutton">Answer Now</button>
                    </div>
                  </div> */}
                  {questionData && questionData.length ? <>
                    {
                      questionData?.map((data, index) => {
                        return <div className="questioninnerin mt-3 mb-3">
                          <h5 className="h5_style">{data.askExpertQuestionUserResponse.question} </h5>

                          <div className="d-flex questioninnerinbottom">
                            <div className="expertquestiondetails">
                              <div className="expertuser"><img src={`https://ui-avatars.com/api/?name=${data?.askExpertQuestionUserResponse?.fullName}&rounded=true`} alt={''} /></div>
                              <div className="questionid"><span>Question ID -</span> {data.askExpertQuestionUserResponseId}<div className="questiondate">{moment(data?.createdAt).format('MM-DD-YYYY')}</div></div>
                            </div>
                            <div className="expertquestiondetailscategory"><span>{data.askExpertQuestionUserResponse.parentInstitutionCategory}</span></div>
                            {!data.isOpen ? <button type="button" className="ctaonebutton" onClick={() => giveAnswer(index, true)}>Answer Now</button> : null}

                            {/*  */}
                          </div>
                          {/* answer part */}
                          {data.isOpen ?
                            <>
                              <div className="mb-4 mt-3 input-container form-group">
                                <textarea className="input form-control textareaform"
                                  onChange={onInputChangeHandler}
                                  name={`answer${data.askExpertQuestionUserResponseId}`}
                                  className="input form-control textareaform"
                                  placeholder=" "
                                  maxLength={750}
                                />
                                <label class="placeholder">Answer Now </label>
                              </div>
                              <div className="d-flex questioninnerinbottom">
                                <div className="expertquestiondetails">
                                  <div>
                                    <h5 className="h5_style"><a href="#questionmodal" onClick={() => openModal(true)}>Click here</a> to see a sample response.</h5>
                                    {isOpenModal &&
                                      <>
                                        <div className="modalspecial qmodal" style={{}}>
                                          <div>
                                            <div className="mybtn">
                                              <button className="myadbtn" onClick={() => openModal(false)}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                                width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
                                                preserveAspectRatio="xMidYMid meet">

                                                <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                                                  fill="#ffffff" stroke="none">
                                                  <path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
                                                </g>
                                              </svg></button>

                                            </div>
                                            <p><b>Subject: You Asked, RIMIB Answered: Learn From The Experts - Read Your Answer Now! </b></p>

                                            <p>Getting answers to financial questions was never that easy. This is the answer to the question asked by you on www.RIMIB.com on Date at Time (Date & Time are variable fields automatically shown by the system) </p>

                                            <p>Your Question (variable field): I am from Toronto and have a corpus of $50,000. My target is $85,000 in 7 years. Give me the best financial products if I am a conservative investor. </p>

                                            <p>The Following Expert Has Answered Your Question: </p>

                                            <p>Expert Name: Riasat Zaman </p>

                                            <p>Address: 40 King St W 15th Floor, Toronto, ON M5H 3Y2, Canada </p>

                                            <p>Phone No.: +1 877-388-6330</p>

                                            <p>Profile Link: (the link to the expert’s public profile page)</p>

                                            <p><b>Expert Insight Just for You - Here's Your ANSWER! </b></p>

                                            <p><i>As a conservative investor in Toronto's financial market, there are a few financial products that may be suitable for your investment goals. </i></p>

                                            <li>GICs: Guaranteed Investment Certificates (GICs) are a low-risk investment option that offer guaranteed returns over a fixed term. With a $50,000 corpus, you could invest in GICs with a range of maturities to match your investment horizon. GICs are FDIC-insured and may offer higher rates than traditional savings accounts. </li>

                                            <li>Bond funds: Bond funds are another conservative investment option. These funds invest in a portfolio of bonds, which typically offer a fixed interest rate and lower volatility than stocks. Bond funds can offer diversification and steady returns over the long term, although there may be fluctuations in returns due to changes in interest rates. </li>

                                            <li>Dividend-paying stocks: If you are willing to take on a slightly higher level of risk, dividend-paying stocks can be a good option. These stocks pay out regular dividends, which can provide a steady stream of income. While there is always the risk of stock price volatility, dividend-paying stocks can offer the potential for higher returns than bonds or GICs. </li>

                                            <li>Balanced funds: Balanced funds invest in a mix of stocks and bonds, offering the potential for higher returns than bonds with less volatility than stocks. These funds typically have a conservative approach and may be a suitable option for investors with a moderate risk tolerance. </li>

                                            <li>ETFs: Exchange-traded funds (ETFs) are another option for conservative investors. These funds track a basket of stocks or bonds and can provide diversification with low fees. While there may be some volatility in returns, ETFs can be a good way to gain exposure to different asset classes with a single investment. </li>

                                            <p><i>Ultimately, the best financial products for you will depend on your investment goals, risk tolerance, and time horizon. It’s important to consult with a financial advisor to determine the most appropriate investment strategy for your individual needs.
</i></p>
                                            <p><b>Disclaimer:</b> </p>

                                            <p><i>Please note that the answer provided is based on the information you provided in your question. It is intended for informational purposes only and should not be construed as comprehensive advice. We recommend that you consult with the expert at the provided contact details and seek professional guidance before making any financial or legal decisions based on the answer provided. Also, refer to our Terms & Conditions for further clarity.</i></p>
                                          </div>
                                        </div>

                                      </>
                                    }
                                    <h5 className="h5_style">This is how your answer appears in customer’s inbox.</h5>
                                  </div>
                                </div>
                                <div className="qbtn">
                                  <button type="button" disabled={!givenAnswer} className="ctaonebutton" onClick={() => sendAnswer(data.askExpertQuestionUserResponseId, data.allocateAskExpertQuestionResponseId)}>Send</button>
                                  <button type="button" className="ctaonebutton" onClick={() => giveAnswer(index, false)}>Cancel</button>
                                </div>
                              </div></> : null

                          }

                          {/*  */}
                        </div>
                      })
                    }
                  </> :
                    <div className="dropdefault text-center">
                      <div className="dropdefaultimage">
                        <img src={dropbox} alt={''} />
                      </div>
                      <div className="dropdefaulttext">
                        <h5>You don't have any Questions. </h5>
                      </div>
                    </div>
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />

    </>

  );
};

export default ExpertDashboardHomeJsx;
