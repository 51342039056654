import React, { useEffect } from 'react'
import { useHistory } from "react-router-dom";
import logo from "../../../assets/images/RimiblogoFinalTransparentfinal.png"
import hamburger from "../../../assets/images/hamburgermenu.jpg"
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const HeaderJsx = (props) => {
  const history = useHistory();
  const token = JSON.parse(sessionStorage.getItem('token'));
  const adminToken = sessionStorage.getItem('adminOldToken');

  const loginAs = sessionStorage.getItem('loginAs');

  const adminLoginData = sessionStorage.getItem('adminLoginData');


  const { pathname } = useLocation()
  const instituteExpert = localStorage.getItem('instituteExpert');




  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > 0) {
      document.body.classList.add('scrolled-down');
    } else {
      document.body.classList.remove('scrolled-down');
    }
    //setScrollY(currentScrollY); 

  };



  useEffect(() => {

    if (history.action === "PUSH") {
      const element = document.querySelector("#navbarSupportedContent");
      const classList = element.classList; // Check if the element has the "active" class.
      const hasActiveClass = classList.contains("show");
      if (hasActiveClass) {
        document.getElementById("mobilemenu").click()
      }

    }

  }, [pathname])
  useEffect(() => { window.addEventListener('scroll', handleScroll); return () => { window.removeEventListener('scroll', handleScroll); }; }, []);

  const logout = () => {
    swal({
      title: "Are you sure you want to Logout ?",
      icon: "warning",
      buttons: [
        'Continue',
        'Logout'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("loginAs");
        sessionStorage.removeItem("auth");
        sessionStorage.removeItem("masterID");
        sessionStorage.removeItem("expertId");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("loginData");
        sessionStorage.removeItem("expertData");
        sessionStorage.removeItem("customerId");

        sessionStorage.removeItem("planName");
        sessionStorage.removeItem("legalName");
        sessionStorage.removeItem("isLoggedIn");
        sessionStorage.removeItem("description");
        sessionStorage.removeItem("flowName");
        sessionStorage.removeItem("flowType");
        sessionStorage.removeItem("subscriptionPlanId");
        sessionStorage.removeItem("planTypeID");
        sessionStorage.removeItem("planData");
        localStorage.removeItem("flowName");
        localStorage.removeItem("flowType");
        localStorage.removeItem("verificationStatus");
        localStorage.removeItem('VerifyInstituteID');
        localStorage.removeItem('AdminFlow');
        localStorage.removeItem('VerifyExpertID');


        history.push("/");
        window.location.reload()
      } else {
        //   swal("Cancelled", "Your imaginary file is safe :)", "error");
      }
    })

  }
  const oldAdmin = sessionStorage.getItem("adminLoginData")
  const backAdmin = () => {
    swal({
      title: "Are you sure you want to Back ?",
      icon: "warning",
      buttons: [
        'Keep Logged in!',
        'Yes, Back!'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {

        sessionStorage.setItem("token", JSON.stringify(adminToken));
        sessionStorage.removeItem("adminOldToken")

        // revert admin login dataa
        oldAdmin && sessionStorage.setItem("loginData", adminLoginData);
        oldAdmin && sessionStorage.removeItem("adminLoginData")
        //sessionStorage.removeItem("loginData")
        sessionStorage.removeItem("expertId")
        sessionStorage.removeItem("description")
        sessionStorage.removeItem("expertData")
        sessionStorage.removeItem("resumeOnBoard")
        sessionStorage.removeItem("loginAs");



        sessionStorage.removeItem("instituteId")
        localStorage.removeItem("slugSet")
        localStorage.removeItem("paymentData")

        localStorage.removeItem("verificationStatus")
        localStorage.removeItem("manageUnverifyExpert")
        localStorage.removeItem("VerifyInstituteID")
        localStorage.removeItem("VerifyExpertID")




        history.push("/admin/opsDashboardHome");
        window.location.reload()
      } else {
        //   swal("Cancelled", "Your imaginary file is safe :)", "error");
      }
    })
  }
  const backInstitute = () => {
    swal({
      title: "Are you sure you want to Back ?",
      icon: "warning",
      buttons: [
        'Keep Logged in!',
        'Yes, Back!'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        let oldToken = localStorage.getItem("instituteExpertToken")
        sessionStorage.setItem("token", oldToken);
        history.push("/dashBoard");
        sessionStorage.removeItem("expertId");
        sessionStorage.removeItem("expertData");
        sessionStorage.removeItem("comingFromInstitute");

        sessionStorage.removeItem("description");

        localStorage.setItem('flowName', 'institute')
        localStorage.setItem('flowType', 'institute')
        localStorage.removeItem("instituteExpert");
        localStorage.removeItem("adInsExpert");

        localStorage.removeItem("instituteExpertToken");

        // window.location.reload()
        // sessionStorage.setItem("token", JSON.stringify(adminToken));
        // sessionStorage.removeItem("adminOldToken")
        // sessionStorage.removeItem("loginData")
        // sessionStorage.removeItem("expertId")
        // sessionStorage.removeItem("instituteId")
        // localStorage.removeItem("slugSet")
        window.location.reload()
      } else {
        //   swal("Cancelled", "Your imaginary file is safe :)", "error");
      }
    })
  }

  return (
    <>
      <header>
        <nav className="navbar navbar-expand-lg sticky-top fixed-top custom nav" id="navbar_top">
          <div className="container">
            <a className="navbar-brand"><Link to={'/'} ><img src={logo} /></Link></a>
            <button className="navbar-toggler" id="mobilemenu" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon">

                <img src={hamburger} />
              </span></button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mb-2 mb-lg-0">
                <li className={` nav-item ${window.location.href?.toLowerCase().includes('home') ? 'active' : ""}`}>
                  <Link to={'/'} className="nav-link">Home</Link>
                </li>
                <li className={` nav-item ${window.location.href?.toLowerCase().includes('asktheexpert') ? 'active' : ""}`}>
                  <Link to={'/askTheExpert'} className="nav-link">Ask the Experts</Link>
                </li>
                <li className={` nav-item ${window.location.href?.toLowerCase().includes('offers') ? 'active' : ""}`}>
                  <Link to={'/offers'} className="nav-link">Offers</Link>
                </li>
                <li className={` nav-item ${window.location.href?.toLowerCase().includes('products') ? 'active' : ""}`}>
                  <Link to={'/products'} className="nav-link">Products</Link>
                </li>
                <li className={` nav-item ${window.location.href?.toLowerCase().includes('blogs') ? 'active' : ""}`}>
                  <Link to={'/blogs'} className="nav-link">Blogs</Link>
                  {/* <a className="nav-link" href="/#homeblog">Blogs</a> */}
                </li>
                {/* <li className={` nav-item ${window.location.href?.toLowerCase().includes('tools') ? 'active' : ""}`}>
                <Link to={'/tools'} className="nav-link">Tools</Link>
                </li> */}
                {/* <li className={` nav-item ${window.location.href?.toLowerCase().includes('search') ? 'active' : ""}`}>
                  <Link to={'/search'} className="nav-link">Search the Experts/Institutions</Link>
                </li> */}
                <li className={` nav-item ${window.location.href?.toLowerCase().includes('searchexpert') ? 'active' : ""}`}>
                  <Link to={'/searchExpert'} className="nav-link">Search Experts</Link>
                </li>
                <li className={` nav-item ${window.location.href?.toLowerCase().includes('searchinstitute') ? 'active' : ""}`}>
                  <Link to={'/searchInstitute'} className="nav-link">Search Institutions</Link>
                </li>
                {
                  loginAs ? loginAs == "expert" ?

                    <li className={` nav-item ${window.location.href?.toLowerCase().includes('searchExpert') ? 'active' : ""}`}>
                      <Link to={'/expertDashboardHome'} className="nav-link">My Account</Link>
                    </li> :
                    loginAs == "customer" ?
                      <li className={` nav-item ${window.location.href?.toLowerCase().includes('searchExpert') ? 'active' : ""}`}>
                        <Link to={'/customerDashboard'} className="nav-link">My Account</Link>
                      </li>
                      : <li className={` nav-item ${window.location.href?.toLowerCase().includes('searchExpert') ? 'active' : ""}`}>
                        <Link to={'/dashboard'} className="nav-link">My Account</Link>
                      </li>
                    : null
                }
                {!token ? <li className={` nav-item ${window.location.href?.toLowerCase().includes('login') ? 'active' : ""}`}>
                  <Link to={'/login'} className="nav-link">Login</Link>
                </li> :

                  instituteExpert ? <li className={` nav-item ${window.location.href?.toLowerCase().includes('logout') ? 'active' : ""}`}>
                    <a className="nav-link" onClick={backInstitute}>Back To Institution</a>
                  </li>
                    : adminToken ?
                      <li className={` nav-item ${window.location.href?.toLowerCase().includes('logout') ? 'active' : ""}`}>
                        <a className="nav-link" onClick={backAdmin}>Back To Admin</a>
                      </li> :
                      <li className={` nav-item ${window.location.href?.toLowerCase().includes('logout') ? 'active' : ""}`}>
                        <a className="nav-link" onClick={logout}>Logout</a>
                      </li>
                }

              </ul>
            </div>
          </div>
        </nav>
      </header>

    </>

  );
};

export default HeaderJsx;
