import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { Loader } from '../../../common/loader/loader.jsx';
import AdvertisementsJsx from './advertisements.jsx'
import { toast } from 'react-toastify';
import { baseURL } from '../../../../constants/applicationConstants.js';
import { nameRegex, postalCodeRegex } from '../../../../shared/helper.js';

export default class Advertisements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offerData: null,
            subCategoryData: null,
            formData: {
                instituteName: '',
                productName: '',
                type: '',
                homeFeatured: '',
                institutionCategory: ''
            },
            page: 1,
            categoryData: null,
            formError: {},
            formSubmitButtonDisable: true,
            isLoading: false,
            expertData: [],
            checked: false
        }
    }

    componentDidMount = () => {
        this.getProduct()
        this.getCategories()
        const token = JSON.parse(sessionStorage.getItem('token'));

        if (!token) {
            this.props.history.push("/admin");
        }
    }
    getCategories = () => {
        this.setState({ isLoading: true })
        let token = JSON.parse(sessionStorage.getItem('token'));
        axios.get(`${baseURL}/institutionCategoriesAndSubCategories`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            this.setState({ categoryData: res.data.data, isLoading: false })
        })
    }
    getProduct = () => {
        this.setState({ isLoading: true })
        let token = JSON.parse(sessionStorage.getItem('token'));
        // axios.get(`${baseURL}/institutionProductsPerPage?page=${this.state.page}&perPage=10`, {
        const { instituteName, productName, institutionCategory, homeFeatured, type } = this.state.formData;

        axios.get(`${baseURL}/institutionAds?page=${this.state.page}&perPage=10&instituteName=${instituteName}&productName=${productName}&institutionCategoryId=${institutionCategory}&isFeatured=${homeFeatured}&planType=${type}`, {
            headers: {
                'x-access-token': token
            }
        }
        ).then(res => {
            this.setState({ offerData: res.data.data, isLoading: false })
        }).catch(err => {
            this.setState({ isLoading: false })
        })
    }
    onPaginationButtonClick = (page) => {
        window.scrollTo(0, 0);
        this.setState({ page: page }, () => this.getProduct())
    }
    handleChange = (e, id) => {
        const { checked } = e.target
        this.setState({
            checked: checked
        })
        this.makeFeature(id, checked)

    }
    makeFeature = (id, action) => {
        const token = JSON.parse(sessionStorage.getItem('token'));

        this.setState({ isLoading: true });
        let data = {
            "isFeatured": action
        }
        axios.put(`${baseURL}/advertisement/${id}/featured`, data, {
            headers: {
                'x-access-token': token
            }
        }).then(res => {
            this.getProduct()
        }).catch(err => {
            this.setState({ isLoading: false });
            toast.error(err?.response.data.error.message);
        })
    }
    handleChange1 = (e, id) => {
        const { checked } = e.target
        this.setState({
            checked: checked
        })
        this.makeFeature1(id, checked)
    }
    makeFeature1 = (id, action) => {
        const token = JSON.parse(sessionStorage.getItem('token'));

        this.setState({ isLoading: true });
        let data = {
            "isTopProduct": action
        }
        axios.put(`${baseURL}/institutionProduct/${id}/topProduct`, data, {
            headers: {
                'x-access-token': token
            }
        }).then(res => {
            this.getProduct()
        }).catch(err => {
            this.setState({ isLoading: false });
            toast.error(err?.response.data.error.message);
        })
    }



    onCatgoryClick = (value) => {
        const subData = this.state.categoryData.filter(itm => itm.institutionCategoryId === value)
        this.setState({ subCategoryData: subData[0]?.subInstitutionCategories })
    }

    onInputChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (name === 'category')
            this.onCatgoryClick(value)

        if (value) {
            if (!this.inputRegexValidation(name, value)) {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "regx",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            } else {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            }
        } else {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [name]: value,
                    },
                    formError: {
                        ...this.state.formError,
                        [name]: "",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }
    }

    inputRegexValidation = (name, value) => {
        if (name === 'instituteName' || name === "productName")
            return nameRegex.test(value);
        if (name === 'postalCode')
            return postalCodeRegex.test(value);

        return true;
    };

    isFromSubmissionBlock = () => {
        let { productName } = this.state.formData;

        if (productName) {
            if (
                this.inputRegexValidation('productName', productName)
            ) {
                this.setState({
                    formSubmitButtonDisable: false
                });
            }
            else {
                this.setState({
                    formSubmitButtonDisable: true
                });
            }
        } else {
            this.setState({
                formSubmitButtonDisable: true
            });
        }
    };

    onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value === "") {
            this.setState({
                formError: {
                    ...this.state.formError,
                    [name]: "req",
                },
            });
        } else {
            if (!this.inputRegexValidation(name, value)) {
                this.setState({
                    formError: {
                        ...this.state.formError,
                        [name]: "regx",
                    },
                });
            }
        }
    };

    onSubmitButtonClick = () => {
        const token = JSON.parse(sessionStorage.getItem('token'));
        this.setState({ isLoading: true });
        const { instituteName, productName, institutionCategory, homeFeatured, type,expertName } = this.state.formData;

        axios.get(`${baseURL}/institutionAds?instituteName=${instituteName}&expertName=${expertName ?? ''}&description=${productName}&institutionCategoryId=${institutionCategory}&isFeatured=${homeFeatured}&planType=${type}`, {
            headers: {
                'x-access-token': token
            }
        }).then(res => {
            this.setState({ offerData: res.data.data, isLoading: false });
        }).catch(err => {
            this.setState({ isLoading: false });
            toast.error(err?.response.data.error.message);
        })
    }


    onViewProfieClick = (url) => {
        this.props.history.push(`/expertPpv/${url}`)
    }
    render() {
        return (
            <div>
                <AdvertisementsJsx
                    offerData={this.state.offerData}
                    subCategoryData={this.state.subCategoryData}
                    categoryData={this.state.categoryData}
                    formData={this.state.formData}
                    onInputChange={this.onInputChange}
                    formError={this.state.formError}
                    onTouchHandler={this.onTouchHandler}
                    onPaginationButtonClick={this.onPaginationButtonClick}
                    formSubmitButtonDisable={this.state.formSubmitButtonDisable}
                    onSubmitButtonClick={this.onSubmitButtonClick}
                    expertData={this.state.expertData}
                    onViewProfieClick={this.onViewProfieClick}
                    handleChange={this.handleChange}
                    handleChange1={this.handleChange1}
                    makeFeature={this.makeFeature}
                />
                {this.state.isLoading && <Loader />}
            </div>
        )
    }
}