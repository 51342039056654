import React from 'react'
import Select from 'react-select';
import DatePicker3 from "react-modern-calendar-datepicker";
import { formatInputValue } from '../../../../shared/helper';
import datecalender from "../../../../assets/images/datecalender.svg"
import { getProperDateMonth, formatCanadianPhoneNumber } from "../../../../shared/helper.js";
import InputMask from 'react-input-mask';

const InstituteDetailsJsx = (props) => {

    const genderList = [
        {
            "label": "Select",
            "value": null
        },
        {
            "label": "Male",
            "value": "Male"
        },
        {
            "label": "Female",
            "value": "Female"
        },
        {
            "label": "Others",
            "value": "Others"
        }
    ]
    const { onInputChangeHandler, handleChange, onDateChange,
        onInstituteButtonClick, editInstitite, changeHideState, formError, onTouchHandler
    } = props;
    const isRequired = true
    const information = props?.wholeData
    const getMuskPattern = (name) =>
        name === 'phone' || name === "secondaryPhone" ? '(999) 999 9999' : 'a9a 9a9'
    return (
        <>
            {!editInstitite ? <div className="myaccountsection institutedetails martop60">
                <div className="myaccountsectionheading">
                    <h3 className="h3_style">Personal Details</h3>
                    <div className="mybtn">
                        <button type="button" className="myadbtn" onClick={() => changeHideState(true)}><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.4597 5.8743L13.8341 4.49994L13.8341 4.49992C14.0686 4.26543 14.1858 4.14818 14.2703 4.03599C14.8063 3.32377 14.8063 2.34278 14.2703 1.63057C14.1858 1.51837 14.0686 1.40112 13.8341 1.16663L13.8341 1.16661L13.8341 1.16658C13.5996 0.932098 13.4823 0.814852 13.3701 0.730405C12.6579 0.194357 11.6769 0.194357 10.9647 0.730405C10.8525 0.814852 10.7353 0.932097 10.5008 1.16658L10.5007 1.16661L9.10713 2.56023C9.90404 3.94047 11.0608 5.08825 12.4597 5.8743ZM7.65253 4.01482L2.19046 9.4769C1.7654 9.90196 1.55287 10.1145 1.41314 10.3756C1.2734 10.6367 1.21446 10.9314 1.09657 11.5209L0.647836 13.7645C0.581314 14.0971 0.548053 14.2634 0.642661 14.358C0.737269 14.4526 0.903573 14.4194 1.23618 14.3529H1.23618L3.47983 13.9041C4.06928 13.7862 4.36401 13.7273 4.62511 13.5876C4.8862 13.4478 5.09873 13.2353 5.52379 12.8102L11.0014 7.33267C9.65352 6.48191 8.51135 5.34748 7.65253 4.01482Z" fill="white"/>
</svg></button>

                    </div>
                </div>
                <div className="form-field">
                    {/* <div className="form-fieldinner">
                        <label>About Me*</label>
                        <div className="fieldans">{information?.description}</div>
                    </div> */}
                    <div className="disgrid grid-2">
                    <div className="form-fieldinner">
                        <label>First Name *</label>
                        <div className="fieldans">{information?.firstName}</div>
                    </div>
                    <div className="form-fieldinner">
                        <label>Last Name *</label>
                        <div className="fieldans">{information?.lastName}</div>
                    </div>
                    <div className="form-fieldinner">
                        <label>Customer Login Email  </label>
                        <div className="fieldans">{information?.email}</div>
                    </div>
                    {/* <div className="form-fieldinner">
                        <label>Alternative Email  </label>
                        <div className="fieldans">{information?.alternativeEmail}</div>
                    </div> */}
                    <div className="form-fieldinner">
                        <label>Primary Phone no.  </label>
                        <div className="fieldans">{information?.phone && formatCanadianPhoneNumber(information?.phone)}</div>
                    </div>
                    {/* <div className="form-fieldinner">
                        <label>Secondary Phone no. </label>
                        <div className="fieldans">{information?.secondaryPhone}</div>
                    </div> */}
                    <div className="form-fieldinner">
                        <label>Date of Birth </label>
                        <div className="fieldans">{getProperDateMonth(information?.dateOfBirth?.day)}-{getProperDateMonth(information?.dateOfBirth?.month)}-{information?.dateOfBirth?.year}</div>
                    </div>
                    <div className="form-fieldinner">
                        <label>Gender </label>
                        <div className="fieldans">{information?.gender}</div>
                    </div>
                    </div>

                </div>
            </div> : null}
            {editInstitite ? <div className="myaccountsection institutedetails martop60">
                <div className="myaccountsectionheading">
                    <h3 className="h3_style">Personal Details</h3>
                    <div className="mybtn">
                        <button type="button" className="myadbtn" onClick={() => changeHideState(false)}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
</g>
</svg></button>
                    </div>
                </div>
                <div className="form-field">
                    {/* <div className="form-fieldinner">
                        <div className="input-container form-group">
                            <input type="text"
                                // className="input form-control"

                                className={
                                    isRequired && formError["description"]
                                        ? "input form-control  is-invalid"
                                        : "input form-control"
                                }
                                maxLength={200}
                                name="description" placeholder=" " value={information.description} onChange={onInputChangeHandler} onBlur={onTouchHandler} />
                            <label className="placeholder">About me</label>
                            <span className="text-danger">
                                {isRequired &&
                                    formError["description"] &&
                                    (formError["description"] === "req"
                                        ? "description is required!"
                                        : "Please enter a proper description!")}
                            </span>
                        </div>
                    </div> */}
                    <div className="form-fieldinner">
                        <div className="input-container form-group">
                            <input type="text"
                                // className="input form-control"

                                className={
                                    isRequired && formError["firstName"]
                                        ? "input form-control  is-invalid"
                                        : "input form-control"
                                }
                                maxLength={30}
                                name="firstName" placeholder=" " value={information.firstName} onChange={onInputChangeHandler} onBlur={onTouchHandler} />
                            <label className="placeholder">First name *</label>
                            <span className="text-danger">
                                {isRequired &&
                                    formError["firstName"] &&
                                    (formError["firstName"] === "req"
                                        ? "firstName is required!"
                                        : "Please enter a proper firstName!")}
                            </span>
                        </div>
                    </div>
                    <div className="form-fieldinner">
                        <div className="input-container form-group">
                            <input type="text"
                                className={
                                    isRequired && formError["lastName"]
                                        ? "input form-control  is-invalid"
                                        : "input form-control"
                                }
                                name="lastName" placeholder=" " value={information.lastName} onChange={onInputChangeHandler} onBlur={onTouchHandler} />
                            <label className="placeholder">Last Name *</label>
                            <span className="text-danger">
                                {isRequired &&
                                    formError["lastName"] &&
                                    (formError["lastName"] === "req"
                                        ? "lastName is required!"
                                        : "Please enter a proper lastName!")}
                            </span>
                        </div>
                    </div>
                    <div className="form-fieldinner">
                        <div className="input-container form-group">
                            <input type="text" className={
                                isRequired && formError["email"]
                                    ? "input form-control  is-invalid"
                                    : "input form-control"
                            } name="email" disabled placeholder=" " value={information.email} onChange={onInputChangeHandler} onBlur={onTouchHandler} />
                            <label className="placeholder">Customer Login Email</label>
                            <span className="text-danger">
                                {isRequired &&
                                    formError["email"] &&
                                    (formError["email"] === "req"
                                        ? "email is required!"
                                        : "Please enter a proper email!")}
                            </span>
                        </div>
                    </div>

                    {/* <div className="form-fieldinner">
                        <div className="input-container form-group">
                            <input type="text" className={
                                isRequired && formError["alternativeEmail"]
                                    ? "input form-control  is-invalid"
                                    : "input form-control"
                            } name="alternativeEmail" placeholder=" " value={information.alternativeEmail} onChange={onInputChangeHandler} onBlur={onTouchHandler} />
                            <label className="placeholder">Alternative Email</label>
                            <span className="text-danger">
                                {isRequired &&
                                    formError["alternativeEmail"] &&
                                    (formError["alternativeEmail"] === "req"
                                        ? "alternativeEmail is required!"
                                        : "Please enter a proper alternativeEmail!")}
                            </span>
                        </div>
                    </div> */}

                    <div className="form-fieldinner">
                        <div className="input-container form-group">

                            <InputMask mask={getMuskPattern('phone')} name="phone" value={information.phone} onChange={onInputChangeHandler} onBlur={onTouchHandler}>
                                {(inputProps) => <input {...inputProps} type="tel" className={
                                    isRequired && formError["phone"]
                                        ? "input form-control  is-invalid"
                                        : "input form-control"
                                } placeholder=" " disableUnderline />}
                            </InputMask>
                            <div className="cut"></div>
                            <label className="placeholder">Primary Phone Number  </label>
                            <span className="text-danger">
                                {isRequired &&
                                    formError["phone"] &&
                                    (formError["phone"] === "req"
                                        ? "phone is required!"
                                        : "Please enter a proper phone!")}
                            </span>
                        </div>
                    </div>
                    {/* <div className="form-fieldinner">
                        <div className="input-container form-group">

                            <InputMask mask={getMuskPattern('secondaryPhone')} name="secondaryPhone" value={information.secondaryPhone} onChange={onInputChangeHandler} onBlur={onTouchHandler}>
                                {(inputProps) => <input {...inputProps} type="tel" className={
                                    isRequired && formError["secondaryPhone"]
                                        ? "input form-control  is-invalid"
                                        : "input form-control"
                                } placeholder=" " disableUnderline />}
                            </InputMask>
                            <div className="cut"></div>
                            <label className="placeholder">Secondary Phone Number </label>
                            <span className="text-danger">
                                {isRequired &&
                                    formError["secondaryPhone"] &&
                                    (formError["secondaryPhone"] === "req"
                                        ? "secondaryPhone is required!"
                                        : "Please enter a proper secondaryPhone!")}
                            </span>
                        </div>
                    </div> */}




                    <div className="disgrid grid-2">
                        <div className="form-fieldinner">
                            <div className="input-container form-group">
                                {/* <input type="text" className="input form-control" name="sizeOfInstitution" placeholder=" " value={totalInformation?.instituteSize?.size} onChange={onInputChangeHandler} />
                                <label className="placeholder">Size of Institution * </label> */}
                                <Select className={`selectbox`}
                                    closeMenuOnSelect={true}
                                    placeholder={" "}
                                    name="gender"
                                    options={genderList}
                                    onChange={(e) => handleChange(e, 'gender')}
                                    value={genderList.filter(ite => ite.value === information.gender)}

                                />
                                <div className="placeholder">Gender</div>
                            </div>
                        </div>
                        <div className="form-fieldinner">
                            <div className="input-container form-group">
                                {/* <input type="text" className="input form-control" name="dateOfIncorporation" placeholder=" " value={moment(information.dateOfIncorporation).format("DD-MM-YYYY")} onChange={onInputChangeHandler} />
                                <label className="placeholder">Date of Incorporation * </label> */}

                                <DatePicker3 className="form-control" name="dateOfBirth"
                                    onChange={(date) => { onDateChange(date) }}
                                    inputPlaceholder="dd/mm/yyyy"
                                    shouldHighlightWeekends
                                    maximumDate={{
                                        year: new Date().getFullYear(),
                                        month: new Date().getMonth() + 1,
                                        day: new Date().getDate()
                                    }}
                                    wrapperClassName="input-container form-group"
                                    renderInput={({ ref }) => (
                                        <>
                                            <input
                                                ref={ref}
                                                placeholder=" "
                                                type="text"
                                                required
                                                className="input form-control"
                                                value={formatInputValue(information.dateOfBirth)}
                                            />
                                            <div className="cut"></div>
                                            <label className="placeholder">Date of Birth </label>
                                            <span className="dateim"><img src={datecalender}  alt={''}/></span>
                                        </>
                                    )}
                                    formatInputText={() => formatInputValue(information.dateOfBirth)}
                                />
                            </div>
                        </div>

                    </div>
                    <button type="button" className="ctaonebutton widthhalf" onClick={() => onInstituteButtonClick()}>Save</button>
                </div>
            </div> : null}
        </>
    );
};

export default InstituteDetailsJsx;
