import React from 'react'
import { NEW_URL } from '../../../../constants/applicationConstants'; import dropbox from "../../../../assets/images/dropbox.svg";
import Sidebar from "../sidebar/sidebar.js";
import moment from 'moment';
import { Pagination } from '../../../common/pagination/pagination';

const CustomerQnaJsx = (props) => {
  const {
    data,onPaginationButtonClick,meta
  } = props;

  return (
    <>
      <section class="">

        <div className="container">
          <div className="row">

            <div className="col-lg-12 col-sm-12 martop40">

              {
                data.length ? data?.map(list => {
                  return (
                  <div className="questioninnerin mb-3 backdeepblue">
                     <h5 className="h5_style"><span>Question:</span> <b>{list.askExpertQuestionUserResponse.question}</b></h5>

                    <h5 className="h5_style"><span>Assigned to :</span></h5>
                    <div className="d-flex questioninnerinbottom">
                      <div className="expertquestiondetails">
                      <div className="expertuser"><img src={!list?.expert?.imageUrl ? `https://ui-avatars.com/api/?name=${list?.expert?.firstName}+${list?.expert?.lastName}&rounded=true` : `${NEW_URL}/${list?.expert?.imageUrl}`} alt={''} /></div>
                      <div className="expName"><span>{`${list?.expert?.firstName} ${list?.expert?.lastName}`}</span>
                      <div className="questiondate">{moment(list.date).format('MM-DD-YYYY hh:mm A')}</div></div>

                      </div>
                      <div className="expertquestiondetailscategory"><span>{list.askExpertQuestionUserResponse.parentInstitutionCategory}</span></div>
                    </div>

                  </div>
                  )
                } ) :

                  <div className="dropdefault text-center">
                    <div className="dropdefaultimage">
                      <img src={dropbox} alt={''} />
                    </div>
                    <div className="dropdefaulttext">
                      <h5>You don't have any pending questions yet. </h5>
                    </div>
                  </div>
              }
              {data.length > 0 && <Pagination
                      limit={10}
                      totalCount={meta?.totalCount}
                      onPaginationButtonClick={onPaginationButtonClick}
                    />}
            </div>
          </div>
        </div>

      </section>
    </>

  );
};

export default CustomerQnaJsx;