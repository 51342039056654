import React, { Component } from "react";
import PasswordPage from "./passwordAuthorization.jsx";
import axios from "axios";
import {
  baseURL
} from "../../../constants/applicationConstants.js";
import { getRoute } from '../../../shared/helper';
import { Loader } from "../loader/loader.jsx";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class passwordAuthorization extends Component {
  constructor(props) {
    super(props)
    this.state = {
      OTP: '',
      timerStart: true,
      isLoading: false,
    }
  }

  OnInputChange = (e) => {
    this.setState({ OTP: e.target.value })
  }

  onTimerComplete = () => {
    this.setState({ timerStart: false })
  }

  onResendOTPClick = () => {
    const expertId = sessionStorage.getItem("expertId") && JSON.parse(sessionStorage.getItem("expertId"))
    let flowType = localStorage.getItem("flowType")
    let caseName = localStorage.getItem("requestType")
    let instituteUSER = sessionStorage.getItem("instituteUSER")

    let data = {
      category: "institute",
      userType : "institute"

    };
    if (caseName === "forgot-password"){
      data.requestType = "forgot-password"
    }
    let dataExpert = {
      category: "expert"
    };
    if (caseName === "forgot-password"){
      dataExpert.requestType = "forgot-password"
    }
    let dataCustomer = {
      category: "customer"
    }
    if (instituteUSER) {
      data.userType = "institute-user"
    } 

    let email = JSON.parse(sessionStorage.getItem("email"))
    this.setState({ isLoading: true });
    axios.post(`${baseURL}/forgotPassword/${email}/sendOTP`, expertId ? dataExpert : flowType === "customer" ? dataCustomer : data).then((res) => {
      sessionStorage.setItem("token", JSON.stringify(res.data?.data?.tokenValidate));
      sessionStorage.setItem("email", JSON.stringify(res.data?.data?.email));
      this.setState({ timerStart: true, isLoading: false })
      toast.success('Resent OTP Successfully')
    }).catch((res) => {
      toast.error(res?.response.data.error.message);
      this.setState({ isLoading: false });
    })
  }

  formSubmitHandler = (e) => {
    this.setState({
      isLoading: true
    })

    e.preventDefault();
    const expertId = sessionStorage.getItem("expertId") && JSON.parse(sessionStorage.getItem("expertId"))
    let token = JSON.parse(sessionStorage.getItem("token"))
    let flowType = localStorage.getItem("flowType")
    let caseName = localStorage.getItem("requestType")
    let coming = sessionStorage.getItem("comingFrom")
    let instituteUSER = sessionStorage.getItem("instituteUSER")
    let email = JSON.parse(sessionStorage.getItem("email"))
    let data = {
      otp: this.state.OTP,
      tokenValidate: token,
      "category": expertId ? "expert" : "institute",
      requestType : "onboarding-flow"
    };
    if (caseName === "forgot-password"){
      delete data.requestType;

      data.requestType = "forgot-password"
    }
    if (coming === "login"){
      data.requestType = "onboarding-flow"
    }
    if (flowType === "customer") {
      // delete data.category
      data.email = email
      data.category = "customer"
    }
    if (instituteUSER) {
      data.userType = "institute-user"
    } 
    let fullUrl = '';
     fullUrl = flowType === "customer" ?  `${baseURL}/customer/otpValidation` : `${baseURL}/${flowType === "expert" ? "expert" : flowType === "customer" ? "customer" : instituteUSER ? "institute-user" : "institute"}/${email}/validateOTP`
    if(caseName === "forgot-password")
    {
      fullUrl = flowType === "customer" ?  `${baseURL}/customer/${email}/validateOTP` : `${baseURL}/${flowType === "expert" ? "expert" : flowType === "customer" ? "customer" : instituteUSER ? "institute-user" : "institute"}/${email}/validateOTP`
    }
    const type = localStorage.getItem('flowName');

    axios.post(fullUrl, data).then((res) => {
      if (res.data.success) {
        this.setState({
          isLoading: false
        })
        if (coming === "login" && caseName !== "forgot-password") {
          expertId ?  this.props.history.push(getRoute('institutional', 'passwordAuth1')) :this.props.history.push(getRoute(type, 'newPassword'));
        } else {          
          this.props.history.push(getRoute(type, 'passwordAuth'));
          // if (flowType === "customer")
          // {
          //   this.props.history.push(getRoute(type, 'passwordAuth'));
          // }
          // else
          // {
          //   this.props.history.push(getRoute(type, 'newPassword'));
          // }
          

        }      } else {
        this.setState({
          isLoading: false
        })
        toast.error(res.data.error.message);


      }
    }).catch((res) => {

      toast.error(res?.response.data.error.message);

      this.setState({
        isLoading: false
      })
    })

  };

  render() {
    return (
      <div>
        <PasswordPage
          OTP={this.state.OTP}
          timerStart={this.state.timerStart}
          OnInputChange={this.OnInputChange}
          onTimerComplete={this.onTimerComplete}
          onResendOTPClick={this.onResendOTPClick}
          resendOtp={this.resendOtp}
          formSubmitHandler={this.formSubmitHandler}
          isLoading={this.state.isLoading}

        />
        {this.state.isLoading && <Loader />}
        <ToastContainer />
      </div>
    );
  }
}

export default passwordAuthorization;
