import React from 'react'
import {
    NEW_URL
} from "../../../../constants/applicationConstants.js";

const LogoJsx = (props) => {
    const {
        imgURL, onFileChange, profileImagePreviewUrl, onNextButtonClick
    } = props;
    
    return (
        <>
            <div className="myaccountsection institutedetails">
                <div className="myaccountsectionheading">
                    <h3 className="h3_style">Your Profile Picture</h3>
                    {/* <div className="mybtn">
                        <button type="button" className="myadbtn" onClick={() => changeLogoState(true)}><img src={Subtract} /></button>
                    </div> */}
                </div>
                <div className="logoform">
                    <div className="form-fieldinner">
                        {/* <img className="dashboardlogo" src={`${NEW_URL}/${imgURL}`} /> */}
                        {
                            profileImagePreviewUrl ?
                                <img
                                    src={profileImagePreviewUrl}
                                    height="170px"
                                    width="250px"
                                    alt="edit profile"
                                /> :

                                <img
                                    src={`${NEW_URL}/${imgURL}`}
                                    height="170px"
                                    width="250px"
                                    alt="edit profile"
                                />

                        }

                        <div className="uploaddetails">
                            {/* <input type="file" onChange={onFileChange}></input> */}
                            <div class="button_outer">
                                <div class="btn_upload">
                                    <input type="file" id="upload_file" name="" onChange={onFileChange} accept="image/png, image/gif, image/jpeg" />
                                    <label for="upload_file">Choose Logo</label>
                                </div>
                            </div>
                            <button type="button" className="ctaonebutton" disabled={!profileImagePreviewUrl} onClick={() => onNextButtonClick()}>Upload Logo</button>
                            {/* <button type="button" className="ctaonebutton" onClick={() => changeLogoState(true)}>Change Logo</button> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LogoJsx;
